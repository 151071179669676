<template>
    <div class="modal" tabindex="-1" :id="id" data-backdrop="static">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content bg-ice">
          <div class="modal-header bg-cosmos p-3">
            <span>{{ headerTitel }}</span>
             <modal-close-button @confirmed="closeModal" />
          </div>
          <div class="modal-body">
            <div v-if="response.exception_list.length > 0">
              <span>{{ $t("global.notification_failed") }}:</span>
              <ul>
                <li v-for="(errorType, index) in exception_error_types " :key="index">
                  <span>{{ errorType }}:</span>
                  <div v-for="(item, index) in response.exception_list" :key="index">
                    <ul>
                      <div v-if="item.error_type == errorType">
                        <li>
                          {{ item.message }}
                        </li>
                      </div>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
            <div v-if="response.success_list.length > 0">
              <span>{{ $t("global.successful") }}:</span>
              <ul>
                <li v-for="(successType, index) in success_message_types" :key="index">
                  <span>{{ successType }}:</span>
                  <div v-for="(item, index) in response.success_list" :key="index">
                    <ul>
                      <div v-if="item.message_type == successType">
                        <li>
                          {{ item.message }}
                        </li>
                      </div>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="modal-footer">
            <div class="row w-100">
              <div class="col-12 text-center">
                <button type="button" class="btn btn-success mr-3" @click="onOk">
                  <span class="font-khand font-weight-bold">ok</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  import { modal } from "@/mixins/Modal";
  import ModalCloseButton from "@/components/global/ModalCloseButton.vue";
  
  export default {
    name: "ResponseModal",
    components: {
      ModalCloseButton
    },
    props: {
      id: {
        type: String,
        default: "response-modal",
      },
      headerTitel: {
      type: String,
      default: "Response",
    },
    },
    data() {
      return {
        response: {
          success_list: [],
          exception_list: []
        },
        success_message_types: [],
        exception_error_types: [],
      };
    },
    mixins: [modal],
    mounted() {
      this.$bus.$on("openResponseModal", (response) => {

        if (response.data.success_list.length != 0 || response.data.exception_list.length != 0) {
          this.response = response.data;
          this.mapTypes();
        } else return; 
        // TODO else falls nötig

        $("#" + this.id).modal({
          backdrop: "static",
          keyboard: false,
        });
      });
    },
    methods: {
      closeModal() {
        $("#" + this.id).modal("hide");
        this.$emit("ok");
      },
      onOk() {
        this.closeModal();
        this.$emit("ok");
      },
      mapTypes() {
        // Clear previous data
        this.success_message_types = [];
        this.exception_error_types = [];

       // Use a Set to automatically avoid duplicates
        const successSet = new Set();
        const exceptionSet = new Set();

       // Add unique message_types from success_list to success_message_types
        this.response.success_list.forEach(item => {
            successSet.add(item.message_type);
        });

       // Add unique error_types from exception_list to exception_error_types
        this.response.exception_list.forEach(item => {
            exceptionSet.add(item.error_type);
        });

       // Convert Sets to arrays
        this.success_message_types = Array.from(successSet);
        this.exception_error_types = Array.from(exceptionSet);
      }    
    },
  };
  </script>
  