<template>
  <div :id="id" class="modal fade">
    <div v-if="entitaeten.length > 0" class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <modal-close-button @confirmed="closeModal" />
          <h4 class="modal-title">{{ $t("global.massmutation") }}</h4>
        </div>

        <!-- Named slot to receive content from the button -->
        <slot name="preModalContent"></slot>

        <ValidationObserver v-slot="{ invalid }">
          <div class="modal-body">
            <div class="row ml-0 mr-0">
              <div class="col-md-12">
                <div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="mut-header-bg">
                        <div
                          v-for="(entitaet, index) in entitaeten"
                          :key="index"
                          @click="setDisplayIndex(index)"
                          class="mut-header pointer"
                          :class="activeHeadline(index)"
                        >
                          <div class="mut-headers">
                            <span class="primary-headline-text">{{
                              entitaet.uebersetzung || entitaet.bezeichnung
                            }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="row mb-3">
                        <div class="col-xl-12 block br-t-l-0">
                          <div class="eingabe-panel-kopf table-wrapper p-3">
                            <!-- v-for für jedes alle mutierbaren Felder dieser Seite -->
                            <div
                              v-for="(datenfeld, index) in entitaeten[
                                displayIndex
                              ].datenfelder"
                              :key="
                                datenfeld.bezeichnung +
                                '-' +
                                displayIndex +
                                '-' +
                                index
                              "
                              class="row"
                            >
                              <!-- Für Eingabetyp Einfachauswahl -->
                              <div
                                v-if="datenfeld.eingabetyp.appkey == 'dropdown'"
                                class="col-xl-6"
                              >
                                <div class="form-group">
                                  <v-select
                                    v-model="datenfeld.value"
                                    :options="datenfeld.auswahlwerte"
                                    :reduce="(v) => v.id"
                                    :getOptionLabel="
                                      (feld) =>
                                        feld.uebersetzung || feld.bezeichnung
                                    "
                                    :placeholder="datenfeld.uebersetzung"
                                  >
                                  </v-select>
                                  <label>{{
                                    datenfeld.uebersetzung ||
                                    datenfeld.bezeichnung
                                  }}</label>
                                </div>
                              </div>
                              <!-- Für Eingabetyp Mehrfachauswahl (momentan nur überschreiben)
                                TODO: entfernen und hinzufügen muss noch ergänzt werden  -->
                              <div
                                v-if="
                                  datenfeld.eingabetyp.appkey ==
                                  'dropdown-multi'
                                "
                                class="col-xl-6"
                              >
                                <div class="form-group">
                                  <v-select
                                    v-model.trim="datenfeld.value"
                                    :options="datenfeld.auswahlwerte"
                                    :reduce="(v) => v.id"
                                    :getOptionLabel="
                                      (feld) =>
                                        feld.uebersetzung || feld.bezeichnung
                                    "
                                    :placeholder="
                                      datenfeld.uebersetzung ||
                                      datenfeld.bezeichnung
                                    "
                                    multiple
                                  >
                                  </v-select>
                                  <label>{{
                                    datenfeld.uebersetzung ||
                                    datenfeld.bezeichnung
                                  }}</label>
                                </div>
                              </div>
                              <!-- Für Eingabetyp Checkbox -->
                              <div
                                v-if="datenfeld.eingabetyp.appkey == 'checkbox'"
                                class="col-xl-12 my-2"
                              >
                                <span class="radio-title mr-5"
                                  >{{
                                    datenfeld.uebersetzung ||
                                    datenfeld.bezeichnung
                                  }}:</span
                                >
                                <div class="col-8 d-flex flex-wrap pl-4 pt-4">
                                  <div class="mr-auto">
                                    <input
                                      id="auswaehlen"
                                      class="form-check-input"
                                      type="radio"
                                      value="select"
                                      v-model="datenfeld.value"
                                    />
                                    <label class="pointer" for="auswaehlen">{{
                                      $t("global.select")
                                    }}</label>
                                  </div>
                                  <div class="mr-auto">
                                    <input
                                      id="entfernen"
                                      class="form-check-input"
                                      type="radio"
                                      value="remove"
                                      v-model="datenfeld.value"
                                    />
                                    <label class="pointer" for="entfernen">{{
                                      $t("global.remove")
                                    }}</label>
                                  </div>
                                  <div>
                                    <input
                                      id="ignorieren"
                                      class="form-check-input"
                                      type="radio"
                                      value="ignore"
                                      v-model="datenfeld.value"
                                      checked="checked"
                                    />
                                    <label class="pointer" for="ignorieren">{{
                                      $t("global.ignore")
                                    }}</label>
                                  </div>
                                </div>
                              </div>
                              <!-- Für Eingabetyp Integer -->
                              <div
                                v-if="datenfeld.eingabetyp.appkey == 'integer'"
                                class="col-xl-6"
                              >
                                <div class="form-group">
                                  <input
                                    v-model.number="datenfeld.value"
                                    class="form-control"
                                    :placeholder="
                                      datenfeld.uebersetzung ||
                                      datenfeld.bezeichnung
                                    "
                                  />
                                  <label>{{
                                    datenfeld.uebersetzung ||
                                    datenfeld.bezeichnung
                                  }}</label>
                                </div>
                              </div>
                              <!-- Für Eingabetyp Datum -->
                              <div
                                v-if="datenfeld.eingabetyp.appkey == 'date'"
                                class="col-xl-6"
                              >
                                <div class="form-group">
                                  <date-picker
                                    date-format="dd.mm.yy"
                                    placeholder=""
                                    :show-icon="true"
                                    @update="(val) => (datenfeld.value = val)"
                                    :inModal="true"
                                  />
                                  <label>{{
                                    datenfeld.uebersetzung ||
                                    datenfeld.bezeichnung
                                  }}</label>
                                </div>
                              </div>
                              <!-- Für Eingabetyp Zeit -->
                              <div
                                v-if="datenfeld.eingabetyp.appkey == 'time'"
                                class="col-xl-6"
                              >
                                <time-field
                                  placeholder=" "
                                  :label="
                                    datenfeld.uebersetzung ||
                                    datenfeld.bezeichnung
                                  "
                                  @update="(val) => (datenfeld.value = val)"
                                />
                              </div>
                              <!-- Für Eingabetyp Einzeiliger Text -->
                              <div
                                v-if="
                                  datenfeld.eingabetyp.appkey == 'singleline'
                                "
                                class="col-xl-6"
                              >
                                <div class="form-group">
                                  <input
                                    v-model="datenfeld.value"
                                    class="form-control"
                                    :placeholder="
                                      datenfeld.uebersetzung ||
                                      datenfeld.bezeichnung
                                    "
                                  />
                                  <label>{{
                                    datenfeld.uebersetzung ||
                                    datenfeld.bezeichnung
                                  }}</label>
                                </div>
                              </div>
                              <!-- Für Eingabetyp Mehrzeiliger Text -->
                              <div
                                v-if="
                                  datenfeld.eingabetyp.appkey == 'multiline'
                                "
                                class="col-xl-10"
                              >
                                <div class="form-group">
                                  <textarea
                                    v-model="datenfeld.value"
                                    class="form-control"
                                    :placeholder="
                                      datenfeld.uebersetzung ||
                                      datenfeld.bezeichnung
                                    "
                                    style="resize: none"
                                  ></textarea>
                                  <label>{{
                                    datenfeld.uebersetzung ||
                                    datenfeld.bezeichnung
                                  }}</label>
                                </div>
                              </div>
                              <!-- Für Eingabetyp CkEditor -->
                              <div
                                v-if="datenfeld.eingabetyp.appkey == 'ckeditor'"
                                class="col-xl-10"
                              >
                                <div class="form-group">
                                  <ckeditor
                                    :editor="editor"
                                    v-model="datenfeld.value"
                                  />
                                  <label>{{
                                    datenfeld.uebersetzung ||
                                    datenfeld.bezeichnung
                                  }}</label>
                                </div>
                              </div>
                              <!-- Für Eingabetyp SingleSelect-Person -->
                              <div
                                v-if="
                                  datenfeld.eingabetyp.appkey ==
                                  'singleselect-person'
                                "
                                class="col-xl-6"
                              >
                                <single-select-person
                                  :key="'person-' + index"
                                  :person="person[index]"
                                  :label="datenfeld.uebersetzung"
                                  @confirmed="setPerson($event, index)"
                                  :editable="true"
                                  :val="
                                    (datenfeld.value =
                                      getPersonIdByIndex(index))
                                  "
                                />
                              </div>
                              <!-- Für Eingabetyp Multiple SingleSelect-Person -->
                              <div
                                v-if="
                                  datenfeld.eingabetyp.appkey ==
                                  'multi-singleselect-person'
                                "
                                class="col-xl-12"
                              >
                                <multi-select-person
                                  :key="'multiperson-' + index"
                                  :personen="multiSingleSelectPersonen[index]"
                                  :label="
                                    datenfeld.uebersetzung ||
                                    datenfeld.bezeichnung
                                  "
                                  :allowOpen="false"
                                  :editable="true"
                                  @confirmed="setpersons($event, index)"
                                  :val="
                                    (datenfeld.value =
                                      setMultiSelectPersonValue(index))
                                  "
                                />
                              </div>
                              <!-- Für Eingabetyp SingleSelect-Firma -->
                              <div
                                v-if="
                                  datenfeld.eingabetyp.appkey ==
                                  'singleselect-company'
                                "
                                class="col-xl-6"
                              >
                                <single-select-firma
                                  :key="'firma-' + index"
                                  :firma="firma[index]"
                                  :label="
                                    datenfeld.uebersetzung ||
                                    datenfeld.bezeichnung
                                  "
                                  @confirmed="setFirma($event, index)"
                                  :editable="true"
                                  :val="
                                    (datenfeld.value = getFirmaIdByIndex(index))
                                  "
                                />
                              </div>
                              <!-- Für Eingabetyp SingleSelect-Dozent -->
                              <div
                                v-if="
                                  datenfeld.eingabetyp.appkey ==
                                  'singleselect-dozent'
                                "
                                class="col-xl-6"
                              >
                                <single-select-person
                                  :key="'dozent-' + index"
                                  :person="dozent[index]"
                                  :label="
                                    datenfeld.uebersetzung ||
                                    datenfeld.bezeichnung
                                  "
                                  :rollen="empfaengerRollen"
                                  @confirmed="setDozent($event, index)"
                                  :editable="true"
                                  :val="
                                    (datenfeld.value =
                                      getDozentIdByIndex(index))
                                  "
                                />
                              </div>
                              <!-- Für Eingabetyp datetime -->
                              <div
                                v-if="datenfeld.eingabetyp.appkey == 'datetime'"
                                class="d-flex col-xl-10"
                              >
                                <div class="form-group pr-5">
                                  <date-picker
                                    class="minw-150-px"
                                    date-format="dd.mm.yy"
                                    placeholer=" "
                                    :show-icon="true"
                                    :initial="
                                      initialDateTimeValue(
                                        datenfeld.interne_bezeichnung
                                      ).date
                                    "
                                    @update="
                                      (val) => setDateInput(datenfeld, val)
                                    "
                                  />
                                  <label>{{
                                    datenfeld.uebersetzung +
                                      " - " +
                                      $t("global.date") ||
                                    datenfeld.bezeichnung +
                                      " - " +
                                      $t("global.date")
                                  }}</label>
                                </div>

                                <div class="form-group">
                                  <div class="input-group">
                                    <input
                                      type="time"
                                      class="form-control minw-150-px"
                                      :value="
                                        initialDateTimeValue(
                                          datenfeld.interne_bezeichnung
                                        ).time
                                      "
                                      @input="setTimeInput(datenfeld, $event)"
                                    />
                                    <div
                                      class="input-group-append text-medium-light h-100 mr-2 d-flex align-items-center"
                                    >
                                      <font-awesome-icon
                                        icon="fa-solid fa-clock"
                                        class="m-auto"
                                      />
                                    </div>
                                  </div>
                                  <label>{{
                                    datenfeld.uebersetzung +
                                      " - " +
                                      $t("global.TIme") ||
                                    datenfeld.bezeichnung +
                                      " - " +
                                      $t("global.TIme")
                                  }}</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" @click="closeModal">
              {{ $t("global.cancel") }}
            </button>
            <button
              v-if="entitaeten.length > 0"
              type="button"
              class="btn btn-primary"
              @click="openConfirmMessageBox"
              :disabled="invalid"
            >
              {{ $t("global.save") }}
            </button>
          </div>
        </ValidationObserver>
      </div>
    </div>
    <div v-else class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <modal-close-button @confirmed="closeModal" />
          <h4 class="modal-title">{{ $t("global.massmutation") }}</h4>
        </div>
        <div class="modal-body">
          <div class="mt-2">
            <p>{{ $t("global.nomutablefields") }}</p>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-default" @click="closeModal">
            {{ $t("global.back") }}
          </button>
        </div>
      </div>
    </div>
    <message-box
      :id="'confirm-' + id"
      ref="confirmMassmutationModal"
      :headerText="$t('global.confirmmassmutation')"
      :text="$t('global.doyoureallywanttomutatethesevalues')"
      :ok="true"
      :cancel="true"
      :okText="$t('global.ok')"
      :cancelText="$t('global.cancel')"
      @ok="speichern"
    />
  </div>
</template>
<script>
import { modal } from "@/mixins/Modal";
import App from "@/AppApi";
import DatePicker from "@/components/Datepicker";
import TimeField from "@/components/TimeField.vue";
import MessageBox from "@/components/Modals/Messagebox";
import ModalCloseButton from "@/components/global/ModalCloseButton.vue";
import ClassicEditor from "/custom_ckeditor/build/ckeditor";
import SingleSelectPerson from "@/components/global/SingleSelectPerson.vue";
import MultiSelectPerson from "@/components/global/MultiSelectPerson.vue";
import SingleSelectFirma from "@/components/global/SingleSelectFirma.vue";
import { apiErrorToAlert } from "@/utils/Errorhandler";

export default {
  name: "MassenmutationModalNeu",
  empfaengerRollen: "",
  mixins: [modal],
  components: {
    DatePicker,
    TimeField,
    SingleSelectPerson,
    MessageBox,
    ModalCloseButton,
    SingleSelectFirma,
    MultiSelectPerson,
  },
  props: {
    propEntities: {
      type: Array,
    },
    customAddition: {
      type: null, // not specified
      default: null,
    },
    id: {
      type: String,
      default: "massenmutation-modal"
    }
  },
  data() {
    return {
      editor: ClassicEditor,
      shown: false,
      entitaeten: [],
      displayIndex: 0,
      multiSingleSelectPersonen: [],
      person: [],
      firma: [],
      dozent: [],
      dateTimes: [],
      empfaengerRollen: this.$CONST("ROLLEN").DOZENTEN,
    };
  },
  computed: {
    initialDateTimeValue() {
      return (feldBez) => {
        const dateTime = this.dateTimes.find(
          (item) => item.feldBez === feldBez
        );
        return dateTime
          ? { date: dateTime.date, time: dateTime.time }
          : { date: null, time: null };
      };
    },
  },
  watch: {},
  async created() {
    this.getFields();
  },
  mounted() {
    this.$bus.$on("open-modal", (id) => {
      this.initEntitaeten();
    });
  },
  methods: {
    initEntitaeten() {
      if (this.entitaeten.length > 0) {
        // Setzen der IDs
        this.entitaeten.forEach((entity, index) => {
          const entityWithSameTable = this.propEntities.find(
            (propEntity) => propEntity.tbl_name == entity.tbl_name
          );
          entity.selectedIds = entityWithSameTable.selectedIds;

          // Setzen des Standardwerts Ignore für jedes Checkbox-Datenfeld
          entity.datenfelder.forEach((datenfeld) => {
            if (
              datenfeld.eingabetyp.appkey === "checkbox" &&
              datenfeld.value == null
            ) {
              datenfeld.value = "ignore";
            }
          });
        });
      }
    },

    getFields() {
      let tbl_names = [];
      this.propEntities.forEach((entity) => {
        tbl_names.push(entity.tbl_name);
      });
      App.get("/massenmutation/", {
        params: { entities: tbl_names.join(",") },
      }).then((response) => {
        this.entitaeten = response.data;
      });
    },
    // inititalizeBeitrag(beitrag) {
    //   if (beitrag) {
    //     this.beitragId = beitrag.id;
    //     this.subventionsbeitrag = beitrag;
    //   } else this.clear();
    // },

    // beitragToJson() {
    //   return this.subventionsbeitrag;
    // },

    openConfirmMessageBox() {
//      this.$refs['confirm-' + this.id].openModal();
      this.$bus.$emit('open-modal', 'confirm-' + this.id)
    },

    speichern() {
      if (!this.validInputs()) {
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text:
            this.$t("global.fieldvalidationfailed") +
            "<br>" +
            this.$t("global.timewithoutdatenotvalid"),
        });
        return;
      }
      let json = {};
      // this.setDateTimeValues();

      json.entitaeten = this.entitaeten;
      json.custom_addition = this.customAddition;
      App.put("/massenmutation/", json)
        .then((response) => {
          console.log(response.data);
          if (response.data.error.length !== 0) {
            this.$notify({
              type: "warn",
              title: this.$t("global.actionfailed"),
              text: this.$t("global.atleastoneelementcouldnotbemutated"),
            });
          } else {
            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text: this.$t("global.allelementsweresuccessfullymutated"),
            });
          }
        })
        .catch((error) => {
          this.$notify(apiErrorToAlert(error));
        })
        .finally(() => {
          this.closeModal();
          this.$emit("mutated");
        });
    },
    clear() {
      if (this.entitaeten.length == 0) return;
      this.entitaeten.forEach((entitaet) => {
        entitaet.datenfelder.forEach((feld) => {
          feld.value = null;
        });
      });

      this.dateTimes = [];
      this.multiSingleSelectPersonen = [];
    },

    closeModal() {
      this.shown = false;
      this.clear();
      this.$bus.$emit("sucheModalClose");
      $("#" + this.id).modal("hide");
    },

    setDisplayIndex(index) {
      // angezeigt Datenfelder switchen über index der Entität
      this.displayIndex = index;
    },
    setPerson(person, index) {
      // ausgewählte Person mit Index in Array abspeichern
      this.$set(this.person, index, person);
    },
    setFirma(firma, index) {
      // ausgewählte Firma mit Index in Array abspeichern
      this.$set(this.firma, index, firma);
    },
    setDozent(dozent, index) {
      // ausgewählten Dozent mit Index in Array abspeichern
      this.$set(this.dozent, index, dozent);
    },
    getPersonIdByIndex(index) {
      // Person aus Array mit gleichem index wie Datenfeld einfügen
      if (this.person[index] && this.person[index].id) {
        return this.person[index].id;
      }
      return null;
    },
    getFirmaIdByIndex(index) {
      // Firma aus Array mit gleichem index wie Datenfeld einfügen
      if (this.firma[index] && this.firma[index].id) {
        return this.firma[index].id;
      }
      return null;
    },
    getDozentIdByIndex(index) {
      // Dozent aus Array mit gleichem index wie Datenfeld einfügen
      if (this.dozent[index] && this.dozent[index].id) {
        return this.dozent[index].id;
      }
      return null;
    },
    setDateInput(feld, val) {
      const element = this.dateTimes.find(
        (item) => item.feldBez == feld.interne_bezeichnung
      );
      if (element) {
        element.date = val;
        this.setDateTimeValues();
      } else {
        this.dateTimes.push({
          feldBez: feld.interne_bezeichnung,
          date: val,
          time: null,
        });
      }
    },
    setTimeInput(feld, event) {
      const element = this.dateTimes.find(
        (item) => item.feldBez == feld.interne_bezeichnung
      );

      if (element) {
        element.time = event.target.value;
        this.setDateTimeValues();
      } else {
        this.dateTimes.push({
          feldBez: feld.interne_bezeichnung,
          date: null,
          time: event.target.value,
        });
      }
    },
    setDateTimeValues() {
      let datenfeld = {};
      this.dateTimes.forEach((item) => {
        this.entitaeten.forEach((entitaet) => {
          datenfeld = entitaet.datenfelder.find(
            (feld) => feld.interne_bezeichnung == item.feldBez
          );
          if (datenfeld) {
            datenfeld.value = item.time
              ? item.date + " " + item.time + ":00"
              : item.date + " 00:00:00";
          }
        });
      });
    },
    validInputs() {
      let isValid = true;
      // check if all datetime inputs are filled
      this.dateTimes.forEach((item) => {
        if (!item.date && item.time) {
          isValid = false;
        }
      });
      return isValid;
    },
    activeHeadline(index) {
      if (this.displayIndex == index) return "mut-selected";
    },
    setpersons(event, index) {
      console.log(event);
      this.$set(this.multiSingleSelectPersonen, index, event);
    },
    setMultiSelectPersonValue(index) {
      if (this.multiSingleSelectPersonen[index]?.length > 0) {
        return this.multiSingleSelectPersonen[index]
          .filter((item) => item?.id)
          ?.map((item) => item.id);
      }
    },
  },
};
</script>

<style></style>
