import Honorar from "@/pages/Finanzen/Honorar";
import Honorarliste from "@/pages/Dashboardlisten/Honorarliste";
import Auftrag from "@/pages/Finanzen/Auftrag";
import Auftragliste from "@/pages/Dashboardlisten/Auftraege";
import Debitor from "@/pages/Finanzen/Debitor";
import Debitorenliste from "@/pages/Dashboardlisten/Debitoren";
import Zahlungseingang from "@/pages/Finanzen/Zahlungseingang";
import Zahlungenliste from "@/pages/Dashboardlisten/Zahlungen";
import Belegliste from "@/pages/Dashboardlisten/Belege";
import Offerte from "@/pages/Finanzen/Offerte";

export default [
  {
    path: "/Finanzen/Honorarliste",
    name: "honorar-liste",
    component: Honorarliste,
  },
  {
    path: "/Finanzen/Honorar/:id?/:anzeige",
    name: "honorar",
    component: Honorar,
    props: (route) => {
      const a = parseInt(route.params.anzeige, 10);
      return {
        id: route.params.id,
        anzeige: isNaN(a) ? 0 : a,
      };
    },
  },
  {
    path: "/finanzen/auftraege/",
    name: "auftraege",
    component: Auftragliste,
  },
  {
    path: "/finanzen/auftrag/:id?/:anzeige",
    name: "Auftrag",
    component: Auftrag,
    props: (route) => {
      const a = parseInt(route.params.anzeige, 10);
      return {
        id: route.params.id,
        anzeige: isNaN(a) ? 0 : a,
      };
    },
  },
  {
    path: "/finanzen/debitoren",
    name: "Debitoren",
    component: Debitorenliste,
  },
  {
    path: "/finanzen/debitor/:id?/:anzeige",
    name: "Debitor",
    component: Debitor,
    props: (route) => {
      const a = parseInt(route.params.anzeige, 10);
      return {
        id: route.params.id,
        anzeige: isNaN(a) ? 0 : a,
      };
    },
  },
  {
    path: "/finanzen/zahlungen",
    name: "Zahlungen",
    component: Zahlungenliste,
  },
  {
    path: "/finanzen/zahlungseingang/:id?/:anzeige",
    name: "Zahlungseingang",
    component: Zahlungseingang,
    props: (route) => {
      const a = parseInt(route.params.anzeige, 10);
      return {
        id: route.params.id,
        anzeige: isNaN(a) ? 0 : a,
      };
    },
  },
  {
    path: "/finanzen/belege",
    name: "Belege",
    component: Belegliste,
  },
  {
    path: "/Offerte/:id?/:anzeige",
    name: "Offerte",
    component: Offerte,
    props: route => {
      const a = parseInt(route.params.anzeige, 10);
      return {
        id: route.params.id,
        anzeige: isNaN(a) ? 0 : a
      };
    }
  },

  /*** Muss überarbeitet werden ***/

  {
    path: "/finanzen/auftrag/",
    name: "auftrag.neu",
    component: () => import("@/pages/Finanzen/Auftrag"),
    props: true,
  },
  {
    path: "/finanzen/auftrag/:id",
    name: "auftrag.id",
    component: () => import("@/pages/Finanzen/Auftrag"),
    props: true,
  },
  {
    path: "/finanzen/auftrag/:id/:anzeige",
    name: "auftrag.id.anzeige",
    component: () => import("@/pages/Finanzen/Auftrag"),
    props: (route) => {
      const a = parseInt(route.params.anzeige, 10);
      return {
        id: route.params.id,
        anzeige: isNaN(a) ? 0 : a,
      };
    },
  },
  {
    path: "/finanzen/beleg/:id",
    name: "BelegMitId",
    props: true,
    component: () => import("@/pages/Finanzen/Beleg"),
  },
  {
    path: "/finanzen/beleg/:id/:anzeige",
    name: "BelegMitIdUndAnzeige",
    props: (route) => {
      const a = parseInt(route.params.anzeige, 10);
      return {
        id: route.params.id,
        anzeige: isNaN(a) ? 0 : a,
      };
    },
    component: () => import("@/pages/Finanzen/Beleg"),
  },
];
