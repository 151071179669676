var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-xl-12 pr-4"},[_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-xl-12 block br-t-l-0"},[_c('div',{staticClass:"eingabe-panel-kopf p-3"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-4"},[_c('div',{staticClass:"row"},[(!_vm.korrespondenz.isMassKorrespondenz)?_c('div',{staticClass:"col-xl-12"},[_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"rules":"required","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
return [_c('textarea',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.korrespondenz.korrespondenzadresse),expression:"korrespondenz.korrespondenzadresse",modifiers:{"trim":true}}],staticClass:"form-control",class:{ 'border-danger': !passed },attrs:{"rows":"5","readonly":!_vm.editable},domProps:{"value":(_vm.korrespondenz.korrespondenzadresse)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.korrespondenz, "korrespondenzadresse", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('label',{class:{ 'text-danger': !passed }},[_vm._v(" "+_vm._s(_vm.$t("global.correspondenceaddress"))+" ")])]}}],null,false,3733170140)})],1)]):_vm._e(),_c('div',{staticClass:"col-xl-12"},[_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"rules":"required","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
return [(!_vm.korrespondenz.versand.isVorlage)?_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.korrespondenz.versand.betreff),expression:"korrespondenz.versand.betreff",modifiers:{"trim":true}}],staticClass:"form-control",class:{ 'border-danger': !passed },attrs:{"readonly":!_vm.editable,"placeholder":" "},domProps:{"value":(_vm.korrespondenz.versand.betreff)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.korrespondenz.versand, "betreff", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}):_vm._e(),(
                        _vm.korrespondenz.versand.isVorlage &&
                        _vm.korrespondenz.versand.translation &&
                        _vm.lang
                      )?_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(
                        _vm.korrespondenz.versand.translation[_vm.lang.kuerzel]
                          .betreff
                      ),expression:"\n                        korrespondenz.versand.translation[lang.kuerzel]\n                          .betreff\n                      ",modifiers:{"trim":true}}],staticClass:"form-control",class:{ 'border-danger': !passed },attrs:{"readonly":!_vm.editable,"placeholder":" "},domProps:{"value":(
                        _vm.korrespondenz.versand.translation[_vm.lang.kuerzel]
                          .betreff
                      )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.korrespondenz.versand.translation[_vm.lang.kuerzel]
                          , "betreff", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}):_vm._e(),_c('label',{class:{ 'text-danger': !passed }},[_vm._v(_vm._s(_vm.$t("global.subject")))])]}}])})],1)]),(!_vm.korrespondenz.isMassKorrespondenz)?_c('div',{staticClass:"col-xl-12"},[_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.korrespondenz.anrede),expression:"korrespondenz.anrede",modifiers:{"trim":true}}],staticClass:"form-control",attrs:{"readonly":!_vm.editable,"placeholder":" "},domProps:{"value":(_vm.korrespondenz.anrede)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.korrespondenz, "anrede", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('label',[_vm._v(_vm._s(_vm.$t("global.salutation")))])])]):_vm._e()])]),_c('div',{staticClass:"col-xl-4"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12"},[_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"rules":"required","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var passed = ref.passed;
return [_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.korrespondenz.versand.ort),expression:"korrespondenz.versand.ort",modifiers:{"trim":true}}],staticClass:"form-control",class:{ 'border-danger': !passed },attrs:{"readonly":!_vm.editable,"placeholder":" "},domProps:{"value":(_vm.korrespondenz.versand.ort)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.korrespondenz.versand, "ort", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('label',{class:{ 'text-danger': !passed }},[_vm._v(" "+_vm._s(_vm.$t("global.city"))+" ")])]}}])})],1)]),_c('div',{staticClass:"col-xl-12"},[_c('div',{staticClass:"form-group"},[_c('date-picker',{attrs:{"date-format":"dd.mm.yy","initial":_vm.korrespondenz.versand.datum,"placeholder":" ","disabled":!_vm.editable,"show-icon":true},on:{"update":function (val) { return (_vm.korrespondenz.versand.datum = val); }}}),_c('label',{class:{ 'text-danger': !_vm.korrespondenz.versand.datum }},[_vm._v(" "+_vm._s(_vm.$t("global.date"))+" ")])],1)])])]),_c('div',{staticClass:"col-xl-4"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-6"},[_c('div',{staticClass:"form-group"},[_c('v-select',{attrs:{"options":_vm.unterschriften,"disabled":!_vm.editable,"placeholder":_vm.$t('global.signature'),"getOptionLabel":function (unterschrift) { return unterschrift.person.personName; }},on:{"input":_vm.unterschrift1Updated},scopedSlots:_vm._u([{key:"option",fn:function(ref){
                          var person = ref.person;
return [_vm._v(" "+_vm._s(person.personName)+" ")]}},{key:"selected-option",fn:function(ref){
                          var person = ref.person;
return [_vm._v(" "+_vm._s(person.id ? person.personName : _vm.$t("global.notlinked"))+" ")]}}]),model:{value:(_vm.unterschrift1),callback:function ($$v) {_vm.unterschrift1=$$v},expression:"unterschrift1"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(_vm._s(_vm.$t("global.taptosearch")))])]),_c('label',[_vm._v(_vm._s(_vm.$t("global.signature1")))])],1)]),_c('div',{staticClass:"col-xl-6"},[_c('div',{staticClass:"form-group"},[_c('v-select',{attrs:{"options":_vm.unterschriften,"disabled":!_vm.editable,"placeholder":_vm.$t('global.signature'),"getOptionLabel":function (unterschrift) { return unterschrift.person.personName; }},on:{"input":_vm.unterschrift2Updated},scopedSlots:_vm._u([{key:"option",fn:function(ref){
                          var person = ref.person;
return [_vm._v(" "+_vm._s(person.personName)+" ")]}},{key:"selected-option",fn:function(ref){
                          var person = ref.person;
return [_vm._v(" "+_vm._s(person.id ? person.personName : _vm.$t("global.notlinked"))+" ")]}}]),model:{value:(_vm.unterschrift2),callback:function ($$v) {_vm.unterschrift2=$$v},expression:"unterschrift2"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(_vm._s(_vm.$t("global.taptosearch")))])]),_c('label',[_vm._v(_vm._s(_vm.$t("global.signature2")))])],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-10"},[_c('div',{staticClass:"form-group"},[_c('textarea',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.korrespondenz.versand.beilagen),expression:"korrespondenz.versand.beilagen",modifiers:{"trim":true}}],staticClass:"form-control",attrs:{"readonly":!_vm.editable,"placeholder":" "},domProps:{"value":(_vm.korrespondenz.versand.beilagen)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.korrespondenz.versand, "beilagen", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_vm._v(" "),_c('label',[_vm._v(_vm._s(_vm.$t("global.Attachments")))])])]),_c('div',{staticClass:"col-xl-1"},[_c('b-button',{directives:[{name:"tooltip",rawName:"v-tooltip"}],staticClass:"mt-3",attrs:{"size":"sm","variant":"outline-primary","title":_vm.$t('global.highlighttextcodes')},on:{"click":_vm.highlightTextcodes}},[_c('font-awesome-icon',{attrs:{"icon":"fa-regular fa-highlighter"}})],1)],1)])])]),(!_vm.korrespondenz.versand.isVorlage)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12"},[_c('ValidationProvider',{attrs:{"rules":"required","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var passed = ref.passed;
return [_c('div',{staticClass:"form-group",style:({
                  border: passed ? '' : '1px solid red',
                  'padding-top': passed ? '' : '0',
                  'margin-top': passed ? '' : '16px',
                })},[_c('ckeditor',{ref:"ckeditorInstance",attrs:{"editor":_vm.editor,"disabled":!_vm.editable},on:{"ready":_vm.captureEditorInstance},model:{value:(_vm.korrespondenz.versand.inhalt),callback:function ($$v) {_vm.$set(_vm.korrespondenz.versand, "inhalt", $$v)},expression:"korrespondenz.versand.inhalt"}})],1)]}}],null,false,2443288794)})],1)]):_vm._e(),(
            _vm.korrespondenz.versand.isVorlage &&
            _vm.korrespondenz.versand.translation &&
            _vm.lang
          )?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12"},[_c('div',{staticClass:"form-group"},[_c('ckeditor',{ref:"ckeditorInstance",attrs:{"editor":_vm.editor,"disabled":!_vm.editable},on:{"ready":_vm.captureEditorInstance},model:{value:(
                  _vm.korrespondenz.versand.translation[_vm.lang.kuerzel].text
                ),callback:function ($$v) {_vm.$set(_vm.korrespondenz.versand.translation[_vm.lang.kuerzel], "text", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"\n                  korrespondenz.versand.translation[lang.kuerzel].text\n                "}})],1)])]):_vm._e()])])])])}
var staticRenderFns = []

export { render, staticRenderFns }