<template>
  <div>
    <!-- REITER -->

    <div class="row mx-0">
      <div
        class="block block-kopf mr-1 clickable"
        v-for="(klasse, index) in klassen"
        :key="klasse[index]"
        @click="setSemesterIndex(index)"
        :style="
          semesterAnzeige.semesterIndex == index &&
          semesterAnzeige.endnotenReiter == false
            ? styleHeadlineSelected
            : styleHeadline
        "
      >
        <span class="col primary-headline-text p-0">{{
          klasse.bezeichnung
        }}</span>
      </div>
      <div
        class="block block-kopf mr-1 clickable"
        v-if="this.klassen.length > 0"
        @click="setSemesterIndexEndnote"
        :style="
          semesterAnzeige.endnotenReiter == true
            ? styleHeadlineSelected
            : styleHeadline
        "
      >
        <span class="col primary-headline-text p-0">{{
          $t("global.finalgrades")
        }}</span>
      </div>

      <div class="block block-kopf mr-1" v-if="this.klassen.length == 0">
        <span class="col primary-headline-text p-0">{{
          $t("global.gradetool")
        }}</span>
      </div>

      <!-- NOTENTOOL -->

      <div class="col-xl-12 block br-t-l-0 pb-4">
        <b-collapse
          v-bind:id="'collapse-Notentool'"
          @shown="setStickyHeight"
          @hidden="resetStickyHeight"
        >
          <div class="eingabe-panel-kopf p-3">
            <!-- SEMESTER UND FÄCHER VORHANDEN? -->

            <div v-if="isBusy" class="text-center text-primary my-2">
              <b-spinner class="align-middle"></b-spinner>
            </div>

            <div v-if="ladeFehler" class="text-center text-danger my-2">
              <strong>{{ $t("global.errorwhileloading") }}</strong>
            </div>

            <div
              class="d-flex justify-content-center align-items-center"
              style="height: 75px"
              v-if="this.klassen.length == 0 && !isBusy"
            >
              {{ $t("global.createsemesterandsubjectstousegradetool") }}
            </div>
            <div
              class="d-flex justify-content-center align-items-center"
              style="height: 75px"
              v-if="
                this.klassen.length > 0 &&
                this.klassen[semesterAnzeige.semesterIndex] &&
                this.klassen[semesterAnzeige.semesterIndex].faecher.length ==
                  0 &&
                !isBusy
              "
            >
              {{ $t("global.createsemesterandsubjectstousegradetool") }}
            </div>

            <!-- SPALTE LINKS -->

            <div
              class="row mx-0"
              v-if="
                (this.klassen.length > 0 &&
                  this.klassen[semesterAnzeige.semesterIndex] &&
                  this.klassen[semesterAnzeige.semesterIndex].faecher.length >
                    0) ||
                (this.klassen.length > 0 &&
                  semesterAnzeige.endnotenReiter == true &&
                  semesterAnzeige.semesterIndex == -1)
              "
            >
              <div class="col-6 p-0">
                <div
                  class="mb-4 form-group"
                  v-if="semesterAnzeige.endnotenReiter == false"
                >
                  <v-select
                    class="col-5 p-0"
                    v-model="
                      promotionsstruktur.semester[semesterAnzeige.semesterIndex]
                        .regelsatz
                    "
                    :getOptionLabel="(regelsatz) => regelsatz.bezeichnung"
                    :options="regelsaetze"
                    :placeholder="'Regelsatz'"
                  >
                    <span slot="no-options">{{
                      $t("global.taptosearch")
                    }}</span>
                  </v-select>
                  <label>Regelsatz</label>
                </div>
                <div
                  class="mb-4 form-group"
                  v-if="semesterAnzeige.endnotenReiter == true"
                >
                  <v-select
                    class="col-5 p-0"
                    v-model="endnotenstruktur.regelsatz"
                    :getOptionLabel="(regelsatz) => regelsatz.bezeichnung"
                    :options="regelsaetze"
                    :placeholder="'Regelsatz Endnoten'"
                  >
                    <span slot="no-options">{{
                      $t("global.taptosearch")
                    }}</span>
                  </v-select>
                  <label>Regelsatz Endnoten</label>
                </div>
                <div
                  :class="stickyClass"
                  v-if="semesterAnzeige.endnotenReiter == false"
                >
                  <vorlage-promotionsstruktur-notenuebersicht
                    v-for="(klasse, index) in displaySemesterUebersicht"
                    :key="klasse[index]"
                    :index="index"
                    :klasse="klasse"
                    :klassen="klassen"
                    :promotionsstruktur="promotionsstruktur"
                    :styleDragElement="styleDragElement"
                    :styleDropzone="styleDropzone"
                    :startDrag="startDrag"
                    :setDropzoneColor="setDropzoneColor"
                    :setDefaultDropzoneColor="setDefaultDropzoneColor"
                    :dragObject="dragObject"
                    :semesterAnzeige="semesterAnzeige"
                    @farbeGeaendert="farbeGeaendert"
                  >
                  </vorlage-promotionsstruktur-notenuebersicht>
                </div>
                <div
                  :class="stickyClass"
                  v-if="semesterAnzeige.endnotenReiter == true"
                >
                  <vorlage-promotionsstruktur-notenuebersicht
                    v-for="(klasse, index) in klassen"
                    :key="klasse[index]"
                    :index="index"
                    :klasse="klasse"
                    :klassen="klassen"
                    :promotionsstruktur="promotionsstruktur"
                    :styleDragElement="styleDragElement"
                    :styleDropzone="styleDropzone"
                    :startDrag="startDrag"
                    :setDropzoneColor="setDropzoneColor"
                    :setDefaultDropzoneColor="setDefaultDropzoneColor"
                    :dragObject="dragObject"
                    :semesterAnzeige="semesterAnzeige"
                  >
                  </vorlage-promotionsstruktur-notenuebersicht>
                </div>
              </div>
              <div class="col-6 p-0">
                <!-- SPALTE RECHTS -->

                <div
                  class="d-flex"
                  v-if="
                    (semesterAnzeige.endnotenReiter == false &&
                      promotionsstruktur.semester[semesterAnzeige.semesterIndex]
                        .sektionsgruppen.length === 0) ||
                    (semesterAnzeige.endnotenReiter == true &&
                      this.endnotenstruktur.sektionsgruppen.length === 0)
                  "
                >
                  <button
                    class="btn btn-success ml-auto"
                    @click="addSektionsgruppe(-1)"
                  >
                    <font-awesome-icon
                      class="mr-1"
                      icon="fa-regular fa-circle-plus"
                    />
                    Sektionsgruppe hinzufügen
                  </button>
                </div>

                <div v-if="semesterAnzeige.endnotenReiter == false">
                  <vorlage-promotionsstruktur-notensektion
                    v-for="(sektionsgruppe, index) in promotionsstruktur
                      .semester[semesterAnzeige.semesterIndex].sektionsgruppen"
                    :key="index"
                    :sektionsgruppe="sektionsgruppe"
                    :styleDragElement="styleDragElement"
                    :styleDropzone="styleDropzone"
                    :sektionsgruppenIndex="index"
                    :dragObject="dragObject"
                    :startDrag="startDrag"
                    :semesterAnzeige="semesterAnzeige"
                    :notentypen="notentypen"
                    :endnotenReiter="semesterAnzeige.endnotenReiter"
                    @addSektionsgruppe="addSektionsgruppe"
                    @removeSektionsgruppe="removeSektionsgruppe"
                  />
                </div>
                <div v-if="semesterAnzeige.endnotenReiter == true">
                  <vorlage-promotionsstruktur-notensektion
                    v-for="(
                      sektionsgruppe, index
                    ) in endnotenstruktur.sektionsgruppen"
                    :key="index"
                    :sektionsgruppe="sektionsgruppe"
                    :styleDragElement="styleDragElement"
                    :styleDropzone="styleDropzone"
                    :sektionsgruppenIndex="index"
                    :dragObject="dragObject"
                    :startDrag="startDrag"
                    :semesterAnzeige="semesterAnzeige"
                    :notentypen="notentypen"
                    :endnotenReiter="semesterAnzeige.endnotenReiter"
                    @addSektionsgruppe="addSektionsgruppe"
                    @removeSektionsgruppe="removeSektionsgruppe"
                  />
                </div>
              </div>
            </div>
          </div>
        </b-collapse>
      </div>

      <div
        class="col-1 rounded-bottom d-flex justify-content-center ml-auto mr-2 py-1 px-2"
        style="background-color: #b5cfdc; height: 30px; max-width: 80px"
      >
        <div
          class="clickable d-flex justify-content-center align-items-center hover-light-blue"
          style="min-width: 80px"
          v-b-toggle="'collapse-Notentool'"
          @click.prevent
        >
          <div class="when-open">
            <font-awesome-icon icon="fa-regular fa-angle-up" size="lg" />
          </div>
          <div class="when-closed">
            <font-awesome-icon icon="fa-regular fa-angle-down" size="lg" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VorlagePromotionsstrukturNotensektion from "@/components/Bildung/Bildungsgang/VorlagePromotionsstrukturNotensektion";
import VorlagePromotionsstrukturNotenuebersicht from "./VorlagePromotionsstrukturNotenuebersicht.vue";
import { BSpinner } from "bootstrap-vue";

export default {
  name: "VorlagePromotionsstruktur",
  components: {
    VorlagePromotionsstrukturNotenuebersicht,
    VorlagePromotionsstrukturNotensektion,
    BSpinner,
  },
  props: {
    klassen: {
      Semesternotensektion: { type: Array, required: true },
    },
    lehrgang: {
      type: Object,
      default: null,
    },
    notentypen: {
      type: Array,
    },
    isBusy: {
      type: Boolean,
      default: false,
    },
    ladeFehler: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dragObject: null,
      styleDragElement: {
        border: "2px solid #124862",
        borderRadius: "10px",
      },
      styleDropzone: {
        borderStyle: "dashed",
        borderColor: "#f5fbff",
        borderWidth: "thin",
      },
      semesterAnzeige: { semesterIndex: 0, endnotenReiter: false },
      semesterUebersicht: [{}],
      styleHeadlineSelected: {
        filter: "none",
      },
      styleHeadline: {
        filter: "brightness(0.9)",
      },
      endnotenstruktur: {
        regelsatz: {},
        sektionsgruppen: [
          {
            bezeichnung: "",
            gesamtgewichtung: "",
            relevanzNoteneingabe: false,
            relevanzBerechnung: true,
            sektionen: [
              {
                bezeichnung: "",
                gewichtung: "",
                sektionsfelder: [
                  {
                    bezeichnung: "",
                    faecher: [],
                    endnotensektionsgruppen: [],
                    gewichtung: "",
                    endnotenNoteneingaben: [],
                  },
                ],
              },
            ],
          },
        ],
      },
      promotionsstruktur: {
        semester: [
          /*{
            color: "#deedf6",
            sektionsgruppen: [
              {
                bezeichnung: "",
                gesamtgewichtung: "",
                relevanzNoteneingabe: false,
                relevanzBerechnung: false,
                sektionen: [
                  {
                    bezeichnung: "",
                    gewichtung: "",
                    sektionsfelder: [
                      {
                        bezeichnung: "",
                        faecher: [],
                        gewichtung: "",
                        notentyp: {},
                        noteneingaben: [],
                      },
                    ],
                  },
                ],
              },
            ],
          },*/
        ],
      },
      promotionsstrukturFarbeGeaendert: true,
      endnotenstrukturFarbeGeaendert: true,
      stickyClass: "top-0",
    };
  },
  created() {},
  mounted() {},
  watch: {
    ["lehrgang.promotionsstruktur"]: {
      deep: true,
      handler: function () {
        if (this.lehrgang.promotionsstruktur) {
          if (
            this.lehrgang.promotionsstruktur.id &&
            this.lehrgang.promotionsstruktur.semester.length > 0
          ) {
            this.promotionsstruktur = this.lehrgang.promotionsstruktur;
          }
          if (this.lehrgang.promotionsstruktur.id) {
            this.promotionsstruktur.id = this.lehrgang.promotionsstruktur.id;
          }
          this.setNoteneingabenPromotionsstruktur();
        } else {
          this.updateSemesterInPromotionsstruktur();
        }
      },
    },
    ["lehrgang.endnotenstruktur"]: {
      deep: true,
      handler: function () {
        if (this.lehrgang.endnotenstruktur) {
          if (
            this.lehrgang.endnotenstruktur.id &&
            this.lehrgang.endnotenstruktur.sektionsgruppen.length > 0
          ) {
            this.endnotenstruktur = this.lehrgang.endnotenstruktur;
          }
          if (this.lehrgang.endnotenstruktur.id)
            this.endnotenstruktur.id = this.lehrgang.endnotenstruktur.id;
        }
        this.setNoteneingabenEndnoten();
      },
    },
    ["klassen"]: {
      deep: true,
      handler: function () {
        if (this.lehrgang.promotionsstruktur) {
          this.updateSemesterInPromotionsstruktur();
          this.setNoteneingabenPromotionsstruktur();
          this.setNoteneingabenEndnoten();
        } else {
          this.promotionsstruktur.semester = [];
          this.updateSemesterInPromotionsstruktur();
        }
        if (this.lehrgang.endnotenstruktur) {
          if (
            this.klassen.length > 0 &&
            this.lehrgang.endnotenstruktur.sektionsgruppen.length == 0 &&
            !this.lehrgang.endnotenstruktur.id
          ) {
            this.setEmptyEndnotenstruktur();
          }
          this.setNoteneingabenPromotionsstruktur();
          this.setNoteneingabenEndnoten();
        } else {
          this.setEmptyEndnotenstruktur();
        }
        this.setEndnotenColor();
        this.setFachColor();
      },
    },
    /*
    promotionsstruktur: {
      deep: true,
      handler: function () {
        if (
          this.promotionsstrukturFarbeGeaendert ||
          this.endnotenstrukturFarbeGeaendert
        ) {
          this.setEndnotenColor();
          this.setFachColor();
        }
        //this.$emit("psChanged", this.promotionsstruktur);
      },
    },
    endnotenstruktur: {
      deep: true,
      handler: function () {
        if (this.endnotenstrukturFarbeGeaendert) {
          this.setEndnotenColor();
        }
        //this.$emit("enChanged", this.endnotenstruktur);
      },
    },*/
  },
  computed: {
    displaySemesterUebersicht() {
      return this.setSemesterUebersicht();
    },
    regelsaetze: {
      get() {
        return this.$store.state.veranstaltung.regelsaetze;
      },
    },
  },
  methods: {
    updateSemesterInPromotionsstruktur() {
      for (let i = 0; i < this.klassen.length; i++) {
        let semester = this.promotionsstruktur.semester.find(
          (semester) => semester.id === this.klassen[i].id
        );
        if (!semester) {
          semester = Object.assign({}, this.klassen[i]);

          semester.sektionsgruppen.push({
            bezeichnung: "",
            gesamtgewichtung: "",
            relevanzNoteneingabe: false,
            relevanzBerechnung: true,
            sektionen: [
              {
                bezeichnung: "",
                gewichtung: "",
                sektionsfelder: [
                  {
                    bezeichnung: "",
                    faecher: [],
                    gewichtung: "",
                    notentyp: {},
                    noteneingaben: [],
                  },
                ],
              },
            ],
          });

          this.promotionsstruktur.semester.push(semester);
        }
      }
    },
    setNoteneingabenPromotionsstruktur() {
      this.promotionsstruktur.semester.forEach((sem) =>
        sem.sektionsgruppen.forEach((sektionsgruppe) =>
          sektionsgruppe.sektionen.forEach((sektion) =>
            sektion.sektionsfelder.forEach((sektionsfeld) => {
              if (!sektionsfeld.noteneingaben) {
                sektionsfeld.noteneingaben = [];
              }
            })
          )
        )
      );
      this.$emit("psChanged", this.promotionsstruktur);
    },
    setNoteneingabenEndnoten() {
      this.endnotenstruktur.sektionsgruppen.forEach((sektionsgruppe) =>
        sektionsgruppe.sektionen.forEach((sektion) =>
          sektion.sektionsfelder.forEach((sektionsfeld) => {
            if (!sektionsfeld.endnotenNoteneingaben) {
              sektionsfeld.endnotenNoteneingaben = [];
            }
          })
        )
      );
      this.$emit("enChanged", this.endnotenstruktur);
    },
    setKlassenColor() {
      for (let i = 0; i < this.klassen.length; i++) {
        if (this.promotionsstruktur.semester[i])
          this.klassen[i].color = this.promotionsstruktur.semester[i].color;
        this.klassen[i].faecher.forEach((fach) => {
          fach.color = this.promotionsstruktur.semester[i].color;
        });
      }
      return this.klassen;
    },
    setFachColor() {
      this.setKlassenColor();
      //Farben Fächer setzen anhand Farbe in Klasse
      for (let i = 0; i < this.promotionsstruktur.semester.length; i++) {
        this.promotionsstruktur.semester[i].sektionsgruppen.forEach(
          (sektionsgruppe) =>
            sektionsgruppe.sektionen.forEach((sektion) =>
              sektion.sektionsfelder.forEach((sektionsfeld) => {
                sektionsfeld.faecher.forEach((fach) =>
                  this.klassen.forEach((klasse) =>
                    klasse.faecher.forEach((klassenFach) => {
                      if (fach.id == klassenFach.id) {
                        this.$set(fach, "color", klasse.color);
                      }
                    })
                  )
                );
              })
            )
        );
      }
      //Farben Noteneingabe setzen anhand Farbe in Klasse
      for (let i = 0; i < this.promotionsstruktur.semester.length; i++) {
        this.promotionsstruktur.semester[i].sektionsgruppen.forEach(
          (sektionsgruppe) => {
            sektionsgruppe.sektionen.forEach((sektion) =>
              sektion.sektionsfelder.forEach((sektionsfeld) => {
                sektionsfeld.noteneingaben.forEach((noteneingabe) =>
                  this.klassen.forEach((klasse) =>
                    klasse.noteneingaben?.forEach((klassenNoteneingabe) => {
                      if (noteneingabe.id == klassenNoteneingabe.id) {
                        this.$set(noteneingabe, "color", klasse.color);
                      }
                    })
                  )
                );
              })
            );
          }
        );
      }
      this.promotionsstrukturFarbeGeaendert = false;
    },
    setEndnotenColor() {
      this.setKlassenColor();
      //Farbe Fächer und Sektionsgruppen im Endnotenreiter setzen anhand Farbe in Klasse
      for (let i = 0; i < this.endnotenstruktur.sektionsgruppen.length; i++) {
        this.endnotenstruktur.sektionsgruppen[i].sektionen.forEach((sektion) =>
          sektion.sektionsfelder.forEach((sektionsfeld) => {
            sektionsfeld.endnotensektionsgruppen.forEach(
              (endnotenSektionsgruppe) => {
                //Farbe setzen für Sektionsgruppen aus Semester
                this.promotionsstruktur.semester.forEach((promoSemester) =>
                  promoSemester.sektionsgruppen.forEach(
                    (promoSektionsgruppe) => {
                      if (promoSektionsgruppe.id == endnotenSektionsgruppe.id) {
                        this.$set(
                          endnotenSektionsgruppe,
                          "color",
                          promoSemester.color
                        );
                      }
                    }
                  )
                );
              }
            );
            sektionsfeld.faecher.forEach((fach) => {
              //Farbe setzen für Faecher aus Semester
              this.klassen.forEach((klasse) => {
                klasse.faecher.forEach((klassenFach) => {
                  if (fach.id == klassenFach.id) {
                    this.$set(fach, "color", klasse.color);
                  }
                });
              });
            });
          })
        );
      }
      //Farbe Noteneingabe im Endnotenreiter setzen anhand Farbe in Klasse
      for (let i = 0; i < this.endnotenstruktur.sektionsgruppen.length; i++) {
        this.endnotenstruktur.sektionsgruppen[i].sektionen.forEach((skt) =>
          skt.sektionsfelder.forEach((skf) => {
            skf.endnotenNoteneingaben.forEach((endnotenNoteneingabe) => {
              this.klassen.forEach((kls) => {
                kls.noteneingaben?.forEach((klassenNoteneingabe) => {
                  if (klassenNoteneingabe.id == endnotenNoteneingabe.id) {
                    this.$set(endnotenNoteneingabe, "color", kls.color);
                  }
                });
              });
            });
          })
        );
      }
      this.endnotenstrukturFarbeGeaendert = false;
    },

    setEmptyEndnotenstruktur() {
      this.endnotenstruktur = {
        sektionsgruppen: [
          {
            bezeichnung: "",
            gesamtgewichtung: "",
            relevanzNoteneingabe: false,
            relevanzBerechnung: true,
            sektionen: [
              {
                bezeichnung: "",
                gewichtung: "",
                sektionsfelder: [
                  {
                    bezeichnung: "",
                    faecher: [],
                    endnotensektionsgruppen: [],
                    gewichtung: "",
                    endnotenNoteneingaben: [],
                  },
                ],
              },
            ],
          },
        ],
      };
    },
    setSemesterIndex(index) {
      this.semesterAnzeige.endnotenReiter = false;
      this.semesterAnzeige.semesterIndex = index;
      //Öffne alle zugeklappten Elemente, wenn der Reiter gewechselt wird
      this.uncollapseAll();
      return this.semesterAnzeige;
    },
    setSemesterIndexEndnote() {
      if (this.promotionsstruktur.id && this.endnotenstruktur.id) {
        this.semesterAnzeige.endnotenReiter = true;
        this.semesterAnzeige.semesterIndex = -1;
        //Öffne alle zugeklappten Elemente, wenn zum Endnoten-Reiter gewechselt wird
        this.uncollapseAll();
        return this.semesterAnzeige;
      } else {
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.educationcoursemustsavedtoaccessfinalgrade"),
        });
      }
    },
    uncollapseAll() {
      // Öffnet alle Elemente, welche eingeklappt waren
      const collapses = document.querySelectorAll('[id*="collapse"]');

      collapses.forEach((collapse) => {
        if (!collapse.classList.contains("show")) {
          const collapseId = collapse.id;
          this.$root.$emit("bv::toggle::collapse", collapseId);
        }
      });
    },
    setSemesterUebersicht() {
      //Faecher alphabetisch sortieren:
      this.klassen.forEach((klasse) =>
        klasse.faecher.sort(function (a, b) {
          var keyA = a.bezeichnungKuerzel,
            keyB = b.bezeichnungKuerzel;
          if (keyA < keyB) return -1;
          if (keyA > keyB) return 1;
          return 0;
        })
      );
      if (!this.semesterAnzeige.endnotenReiter && this.klassen.length > 0) {
        for (let i = 0; i <= this.semesterAnzeige.semesterIndex; i++) {
          this.semesterUebersicht[i] = this.klassen[i];
        }
        this.semesterUebersicht.length = 1 + this.semesterAnzeige.semesterIndex;
      }
      return this.semesterUebersicht;
    },
    startDrag(evt, dragObject, typ) {
      if (typ === "sektionsgruppe") {
        //klasse von sektionsgruppe finden und dann die klassencolor dem dragobject zuweisen.
        this.promotionsstruktur.semester.forEach((semester) => {
          semester.sektionsgruppen.forEach((sektionsgruppe) => {
            if (sektionsgruppe.id === dragObject.id) {
              dragObject.color = semester.color;
              return;
            }
          });
        });
      }

      evt.dataTransfer.dropEffect = "move";
      evt.dataTransfer.effectAllowed = "move";
      evt.dataTransfer.setData("fach", dragObject);
      this.dragObject = dragObject;
      if (typ) this.dragObject.typ = typ;
    },
    setDropzoneColor() {
      this.styleDropzone.borderStyle = "dashed";
      this.styleDropzone.borderColor = "#124862";
    },
    setDefaultDropzoneColor() {
      this.styleDropzone.borderColor = "#f5fbff";
    },
    addSektionsgruppe(sektionsgruppenIndex) {
      if (this.semesterAnzeige.endnotenReiter == false) {
        const defaultSektionsgruppe = {
          bezeichnung: "",
          gesamtgewichtung: "",
          relevanzNoteneingabe: false,
          relevanzBerechnung: true,
          sektionen: [
            {
              bezeichnung: "",
              gewichtung: "",
              sektionsfelder: [
                {
                  bezeichnung: "",
                  faecher: [],
                  gewichtung: "",
                  notentyp: {},
                  noteneingaben: [],
                },
              ],
            },
          ],
        };

        this.promotionsstruktur.semester[
          this.semesterAnzeige.semesterIndex
        ].sektionsgruppen.splice(
          sektionsgruppenIndex + 1,
          0,
          defaultSektionsgruppe
        );
      } else {
        const defaultEndnotensektionsgruppe = {
          bezeichnung: "",
          gesamtgewichtung: "",
          relevanzNoteneingabe: false,
          relevanzBerechnung: true,
          sektionen: [
            {
              bezeichnung: "",
              gewichtung: "",
              sektionsfelder: [
                {
                  bezeichnung: "",
                  faecher: [],
                  endnotensektionsgruppen: [],
                  gewichtung: "",
                  endnotenNoteneingaben: [],
                },
              ],
            },
          ],
        };

        this.endnotenstruktur.sektionsgruppen.splice(
          sektionsgruppenIndex + 1,
          0,
          defaultEndnotensektionsgruppe
        );
      }

      /*	
      if (this.semesterAnzeige.endnotenReiter == false) {
        this.promotionsstruktur.semester[
          this.semesterAnzeige.semesterIndex
        ].sektionsgruppen.push({
          bezeichnung: "",
          gesamtgewichtung: "",
          relevanzNoteneingabe: false,
          relevanzBerechnung: true,
          sektionen: [
            {
              bezeichnung: "",
              gewichtung: "",
              sektionsfelder: [
                {
                  bezeichnung: "",
                  faecher: [],
                  gewichtung: "",
                  notentyp: {},
                  noteneingaben: [],
                },
              ],
            },
          ],
        });
      } else {
        this.endnotenstruktur.sektionsgruppen.push({
          bezeichnung: "",
          gesamtgewichtung: "",
          relevanzNoteneingabe: false,
          relevanzBerechnung: true,
          sektionen: [
            {
              bezeichnung: "",
              gewichtung: "",
              sektionsfelder: [
                {
                  bezeichnung: "",
                  faecher: [],
                  endnotensektionsgruppen: [],
                  gewichtung: "",
                  endnotenNoteneingaben: [],
                },
              ],
            },
          ],
        });
      }*/
    },
    removeSektionsgruppe(sektionsgruppenIndex) {
      if (this.semesterAnzeige.endnotenReiter == false) {
        this.promotionsstruktur.semester[
          this.semesterAnzeige.semesterIndex
        ].sektionsgruppen.splice(sektionsgruppenIndex, 1);
      } else {
        this.endnotenstruktur.sektionsgruppen.splice(sektionsgruppenIndex, 1);
      }
    },
    farbeGeaendert(json) {
      this.promotionsstruktur.semester[json.index].color = json.color;

      this.promotionsstrukturFarbeGeaendert = true;
      this.endnotenstrukturFarbeGeaendert = true;
    },
    setStickyHeight() {
      /* Setzt die Sticky-Klasse mit Verzögerung, damit die Animation beim öffnen des Notentools flüssiger aussieht */
      setTimeout(() => {
        this.stickyClass = "sticky top-90";
      }, 10);
    },
    resetStickyHeight() {
      this.stickyClass = "top-0";
    },
  },
};
</script>

<style lang="scss"></style>
