<template>
    <div class="modal" tabindex="-1" :id="id" data-backdrop="static">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content bg-ice">
          <div class="modal-header bg-cosmos p-3">
            <span>{{ headerText }}</span>
            <modal-close-button @confirmed="closeModal()" />
          </div>
          <div class="modal-body fs-14">
            <div class="row mb-3">
              <div class="col-12 mb-2">
                <span class="text-bold">
                 Bitte geben sie den gewünschten Monat und das Jahr ein
                </span>
              </div>
                <div class="col-3">
                    <div class="form-group">
                    <input
                        type="number"
                        v-model="month"
                        label="Monat"
                        placeholder="MM"
                        class="form-control input"
                        min="1"
                        max="12"
                    />
                    </div>
                </div>
                <div class="col-3">
                    <div class="form-group">
                    <input
                        type="number"
                        v-model="year"
                        label="Jahr"
                        placeholder="JJJJ"
                        class="form-control input"
                        min="2024"
                    />
                    </div>
                </div>
                </div>
            <div class="row mb-3">
              <div class="col-12">
                <span class="text-bold"> Berater wählen</span>
              </div>
              <div class="col-6">
                <div class="form-group p-0">
                    <single-select-person
                      id="consultant"
                      :person="consultant"
                      :allowOpen="false"
                      :rollen="filterRollen"
                      :activeonly="true"
                      :label="''"
                      editable
                      @confirmed="setConsultant"
                    />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="row w-100">
              <div class="col-12 p-0 d-flex justify-content-between text-center">
                <button
                  type="button"
                  class="btn btn-danger mr-3"
                  data-dismiss="modal"
                  @click="onCancel"
                >
                  <span class="font-khand font-weight-bold">{{
                    $t("global.cancel")
                  }}</span>
                </button>
                <button
                  type="button"
                  class="btn btn-success"
                  @click="carbone()"
                  :disabled="!formIsValid"
                >
                  <span class="font-khand font-weight-bold">{{ okText }}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <pdf-layer
      id="pdf-layer-auswahl-absenzen-modal"
      ref="pdfLayerAuswahlAbsenzenModal"
      :src="{
        url: pdfPath,
        withCredentials: true,
      }"
      :files="pdfFiles"
    ></pdf-layer>
    </div>
  </template>

<script>
  import CarboneApi from "@/CarboneApi";
  import server from "@/server";

  import { modal } from "@/mixins/Modal";

  import filter from "@/mixins/Filter";

  import ModalCloseButton from "@/components/global/ModalCloseButton";
  import SingleSelectPerson from "@/components/global/SingleSelectPerson.vue";


  export default {
    name: "AuswahlAbsenzenModal",
    components: {
      ModalCloseButton,
      SingleSelectPerson,
    },
    mixins: [modal, filter],
    props: {
      id: {
        type: String,
        default: "auswahl-absenzen-modal",
      },
      headerText: {
        type: String,
        default: "Absenzen ausgeben",
      },
      mitarbeiter: {
      type: Object,
      required: false,
    },
      okText: {
        type: String,
        default: "OK",
      },
    },
    data() {
      return {
        headerTitel: null,
        date: null,
        consultant: null,
        type: 0,
        filterRollen: this.$CONST("ROLLEN").BERATER,
        language: "DE",
        month: "01",
        year: "2024",
        pdfPath: "",
        pdfFiles: [],
      };
    },
    computed: {
      formIsValid() {
        if (!this.consultant) return false;
        return true;
      },
    },
    mounted() { },
    methods: {
      onCancel() {
        this.closeModal();
      },
      onOk() {
        this.closeModal();

        let json = {
          date: this.date,
          consultant: this.consultant
        };
        this.$emit("ok", json);
      },
      setDate(val) {
        this.date = val;
      },
      setConsultant(consultant) {
        this.consultant = consultant;
        this.formIsValid = true
      },
      carbone() {

      CarboneApi.get(
        "carbone-absenzmeldung/?consultant=" +
          this.consultant.id +
          "&month=" +
          this.month +
          "&year=" +
          this.year +
          "&mitarbeiter=" +
          this.mitarbeiter.id
      ).then((response) => {

          this.pdfFiles = response.data.map((item) => ({
            url: server.url + item.url,
            name: item.name,
            count: item.count,
            size: item.name,
          }));

          this.pdfFiles.length > 0
            ? this.$refs.pdfLayerAuswahlAbsenzenModal.show()
            : this.$notify({
                type: "warn",
                title: this.$t("global.actionfailed"),
                text: "Es ist ein Fehler aufgetreten",
              });
        })
        .catch((error) => {
          console.error("Error fetching PDF files:", error);
        })
        .finally(() => {
          this.$refs.pdfLayerAuswahlAbsenzenModal.loading = false;
        });
      },
    },
  };
  </script>
