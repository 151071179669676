<template>
  <button
    type="button"
    class="close"
    aria-label="Close"
    @click="close"
    v-tooltip.hover
    :title="$t('global.closemodal')"
  >
    <span aria-hidden="true">
      <font-awesome-icon icon="fa-light fa-times" :style="{ color: 'white' }" />
    </span>
  </button>
</template>
  
  <script>
export default {
  name: "ModalCloseButtonNew",
  methods: {
    close() {
      this.$emit("confirmed");
    },
  },
};
</script>
  
  <style>
</style>
  