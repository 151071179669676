var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-xl-12 pr-4",class:{ 'col-xl-12': !_vm.showNavbar }},[_c('container-headline',{attrs:{"headline":_vm.$t('global.receiversender'),"col":3}}),_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-xl-12 block br-t-l-0"},[_c('div',{staticClass:"eingabe-panel-kopf p-3"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-2"},[_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"rules":"required","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
return [_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.korrespondenz.kuerzel),expression:"korrespondenz.kuerzel",modifiers:{"trim":true}}],staticClass:"form-control",class:{ 'border-danger': !passed },attrs:{"readonly":!_vm.editable},domProps:{"value":(_vm.korrespondenz.kuerzel)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.korrespondenz, "kuerzel", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('label',{class:{ 'text-danger': !passed }},[_vm._v(" "+_vm._s(_vm.$t("global.shortdesignation"))+" ")])]}}])})],1)]),_c('div',{staticClass:"col-xl-3"},[_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"rules":"required","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
return [_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.korrespondenz.bezeichnung),expression:"korrespondenz.bezeichnung",modifiers:{"trim":true}}],staticClass:"form-control",class:{ 'border-danger': !passed },attrs:{"readonly":!_vm.editable},domProps:{"value":(_vm.korrespondenz.bezeichnung)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.korrespondenz, "bezeichnung", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('label',{class:{ 'text-danger': !passed }},[_vm._v(" "+_vm._s(_vm.$t("global.designation"))+" ")])]}}])})],1)]),_c('div',{staticClass:"col-xl-2"},[_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"rules":"required","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
return [_c('v-select',{class:{ 'border-danger': !passed },attrs:{"options":_vm.kategorien,"disabled":!_vm.editable,"placeholder":_vm.$t('global.category'),"getOptionLabel":function (kategorie) { return kategorie.bezeichnung; }},model:{value:(_vm.korrespondenz.korrespondenzkategorie),callback:function ($$v) {_vm.$set(_vm.korrespondenz, "korrespondenzkategorie", $$v)},expression:"korrespondenz.korrespondenzkategorie"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(_vm._s(_vm.$t("global.taptosearch")))])]),_c('label',{class:{ 'text-danger': !passed }},[_vm._v(" "+_vm._s(_vm.$t("global.category"))+" ")])]}}])})],1)]),_c('div',{staticClass:"col-xl-2"},[_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"rules":"required","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
return [_c('v-select',{class:{ 'border-danger': !passed },attrs:{"disabled":!_vm.editable,"label":"bezeichnung","options":_vm.langs,"placeholder":_vm.$t('global.defaultlanguage'),"reduce":function (lang) { return lang.id; }},model:{value:(_vm.korrespondenz.defaultsprache),callback:function ($$v) {_vm.$set(_vm.korrespondenz, "defaultsprache", $$v)},expression:"korrespondenz.defaultsprache"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(_vm._s(_vm.$t("global.taptosearch")))])]),_c('label',{class:{ 'text-danger': !passed }},[_vm._v(" "+_vm._s(_vm.$t("global.defaultlanguage"))+" ")])]}}])})],1)]),_c('div',{staticClass:"col-xl-2"},[_c('div',{staticClass:"form-group"},[_c('button',{staticClass:"btn",class:{
                  'btn-primary': _vm.korrespondenz.briefpapier,
                },attrs:{"disabled":!_vm.editable},on:{"click":_vm.setBriefpapier}},[_c('span',[_vm._v(_vm._s(_vm.$t("global.stationery")))])])])]),_c('div',{staticClass:"col-xl-1"},[_c('div',{staticClass:"form-group"},[(_vm.berechtigungen.m_korrespondenz.update)?_c('button',{staticClass:"btn btn-primary",on:{"click":_vm.openTranslationModal}},[_c('span',[_vm._v(_vm._s(_vm.$t("global.translate")))])]):_vm._e()])])])])])]),_c('translation-modal',{ref:"TranslationModal",attrs:{"id":"translation-modal","korrespondenz":_vm.korrespondenz,"langs":_vm.langs},on:{"translate":_vm.translate}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }