var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"col-12 pl-0"},[_c('div',{class:{ 'mt-30-px': !_vm.showNavbar }},[_c('notifications'),(_vm.showNavbar)?_c('navbar',{attrs:{"docsName":_vm.docsName}}):_vm._e(),_c('div',{staticClass:"row ml-0 px-5"},[(_vm.showNavbar)?_c('div',{staticClass:"col-xl-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12 block mb-3 pt-0"},[_c('head-menu',{staticClass:"d-xl-flex",attrs:{"col":12,"headline":_vm.$t('global.finalgradeoverviewcourse') +
                  ' ' +
                  (this.endnotenpromotion.lehrgang.bezeichnung
                    ? this.endnotenpromotion.lehrgang.bezeichnung
                    : '')}})],1)])]):_vm._e()]),_c('div',{staticClass:"row",staticStyle:{"padding":"0 3rem 0 3.95rem !important"}},_vm._l((_vm.endnotenpromotion.sektionsgruppen),function(gruppe,index){return _c('div',{key:gruppe[index],staticClass:"block block-kopf mr-1 clickable",style:(_vm.sektionsgruppenIndex == index
              ? _vm.styleHeadlineSelected
              : _vm.styleHeadline),on:{"click":function($event){return _vm.setSektionsgruppenIndex()}}},[_c('span',{staticClass:"col primary-headline-text p-0"},[_c('router-link',{staticClass:"primary-headline-text nav-link p-0",class:{ active: _vm.anzeige === 0 },attrs:{"to":{
                name: 'Endnotenuebersicht',
                params: {
                  lehrgangid: _vm.lehrgangid,
                  anzeige: index,
                },
              }}},[_vm._v(_vm._s(gruppe.sektionsgruppe.bezeichnung))])],1)])}),0),_c('div',{staticClass:"bock block-kopf",staticStyle:{"padding":"0 3rem 0 3.95rem !important"}},[_c('div',{staticClass:"row block block-kopf d-flex justify-content-between",staticStyle:{"padding":"0.75rem 0 0.25rem 0 !important","border-radius":"0 !important"}},[_c('div',{staticClass:"ml-4"},[(_vm.berechtigungen.b_bildung_bildungsgang.delete)?_c('button',{staticClass:"btn btn-success",on:{"click":_vm.speichern,"shortkey":_vm.speichern}},[_c('span',[_vm._v(_vm._s(_vm.$t("global.save")))])]):_vm._e(),_c('button',{directives:[{name:"show",rawName:"v-show",value:(!this.bearbeitungGesperrt),expression:"!this.bearbeitungGesperrt"}],staticClass:"ml-3 btn btn-secondary",attrs:{"disabled":this.endnotenpromotion.sektionsgruppen[
                  this.sektionsgruppenIndex
                ].notenpromotionen.length == 0},on:{"click":_vm.bearbeitungSperren}},[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":"fa-solid fa-lock"}}),_c('span',[_vm._v(_vm._s(_vm.$t("global.blockediting")))])],1),_c('button',{directives:[{name:"show",rawName:"v-show",value:(
                this.bearbeitungGesperrt &&
                !_vm.berechtigungen.b_bildung_bildungsgang.delete
              ),expression:"\n                this.bearbeitungGesperrt &&\n                !berechtigungen.b_bildung_bildungsgang.delete\n              "}],staticClass:"ml-3 btn btn-secondary",attrs:{"disabled":""}},[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":"fa-solid fa-lock"}}),_c('span',[_vm._v(_vm._s(_vm.$t("global.editingblocked")))])],1),_c('button',{directives:[{name:"show",rawName:"v-show",value:(
                this.bearbeitungGesperrt &&
                _vm.berechtigungen.b_bildung_bildungsgang.delete
              ),expression:"\n                this.bearbeitungGesperrt &&\n                berechtigungen.b_bildung_bildungsgang.delete\n              "}],staticClass:"ml-3 btn btn-secondary",attrs:{"disabled":this.endnotenpromotion.sektionsgruppen[
                  this.sektionsgruppenIndex
                ].notenpromotionen.length == 0},on:{"click":_vm.bearbeitungEntsperren}},[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":"fa-solid fa-unlock"}}),_c('span',[_vm._v(_vm._s(_vm.$t("global.unlockediting")))])],1),_c('button',{staticClass:"ml-3 btn btn-primary",on:{"click":_vm.erstelleExcel}},[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":"fa-duotone fa-print"}}),_c('em',[_vm._v(_vm._s(_vm.$t("global.createexcel")))])],1)]),_c('div',{staticClass:"mr-2"},[_c('button',{staticClass:"mr-3 btn btn-primary",on:{"click":_vm.abbrechen}},[_c('span',[_vm._v(_vm._s(_vm.$t("global.cancel")))])])])])]),_c('div',{staticClass:"row ml-0 px-5"},[_c('div',{staticClass:"col-xl-12 block belegliste text-break",staticStyle:{"width":"100% !important","border-radius":"0 0 10px 10px"}},[(_vm.noEntries)?_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t("global.nodataavailable"))+" ")]):_vm._e()])])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }