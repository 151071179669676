<template>
  <div>
    <div class="row">
      <div class="col-12">
        <container-headline
          class="col-xl-12"
          :headline="
            $t('global.clearingitems') + ' (' + verrechnungspositionsCount + ')'
          "
          :col="3"
        >
          <div class="row">
            <div class="ml-4 mr-2">
              <button
                class="btn btn-success"
                @click="showPositionModal()"
                v-if="berechtigungen.m_finanzen.create"
              >
                <font-awesome-icon icon="fa-regular fa-plus"/>
                {{ $t("global.new") }}
              </button>
            </div>
            <div class="mr-2">
              <button
                v-if="berechtigungen.m_finanzen.delete"
                class="btn btn-danger mr-2"
                :disabled="selectedIds.length < 1"
                @click="loeschen"
              >
                <font-awesome-icon icon="fa-duotone fa-trash" class="mr-1"/>
                {{ $t("global.delete") }}
              </button>
              <button
                class="btn btn-primary mr-2"
                @click="
                exportToAbacusWithPlugin(
                  `clearing-items?eventid=${veranstaltung}`,
                  `Abacus_${veranstaltung}_${kuerzel}`
                ); exportDate = new Date().toString()
              "
              v-if="berechtigungen.m_finanzen.create"
              >
              <span class="mr-2"
              ><font-awesome-icon icon="fa-regular fa-file-spreadsheet"
              /></span>
                {{ $t("global.abacusexport") }}
              </button>
              <span class="text-bold" v-if="exportDate != null">{{ $t("global.lastexported") }} {{ formatDate(exportDate) }}</span>
            </div>
          </div>
        </container-headline>
        <div class="row col-xl-12" :key="positionenKey">
          <div class="col-xl-12 block belegliste br-t-l-0">
            <div>
              <b-table
                ref="selectableTable"
                tbody-tr-class="item"
                :items="verrechnungen"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :busy="isBusy"
                sort-icon-left
                fixed
                show-empty
                selectable
                select-mode="range"
                @row-dblclicked="editieren"
                thead-tr-class="pointer"
              >
                <template v-slot:head(selected)="row">
                  <b-form-checkbox
                    v-model="row.selected"
                    @input="selectAllRows(row)"
                  ></b-form-checkbox>
                </template>

                <template v-slot:cell(selected)="row">
                  <b-form-checkbox
                    v-model="row.item.selected"
                    @input="onRowSelected(row)"
                  ></b-form-checkbox>
                </template>

                <template #table-busy>
                  <div class="text-center text-primary my-2">
                    <b-spinner class="align-middle"></b-spinner>
                  </div>
                </template>

                <template #empty>
                  <div v-if="ladeFehler" class="text-center text-danger my-2">
                    <strong>{{ $t("global.errorwhileloading") }}</strong>
                  </div>
                  <div v-if="!ladeFehler" class="text-center text-primary my-2">
                    <strong>{{ $t("global.nodataavailable") }}</strong>
                  </div>
                </template>
                <!--<template v-slot:cell(pflicht)="row">
                  <i
                    class="fa"
                    :class="{
                      'fa-check': row.item.pflicht,
                      'fa-times': !row.item.pflicht,
                    }"
                  />
                </template>-->
              </b-table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <verrechnungsposition-modal
      ref="verrechnungspositionModal"
      id="verrechnungsposition-modal"
      :veranstaltung="veranstaltung"
      :position="verrechnung"
      @position-changed="positionChanged"
    ></verrechnungsposition-modal>
  </div>
</template>

<script>
import Api from "@/Api";
import {apiErrorToAlert} from "@/utils/Errorhandler";
import {BSpinner} from "bootstrap-vue";
import ContainerHeadline from "@/components/ContainerHeadline";
import VerrechnungspositionModal from "@/components/Bildung/Klasse/VerrechnungspositionModal";
import {v4 as uuid} from "uuid";
import reiter from "@/mixins/Reiter";
import xmlExport from "@/mixins/XmlExport";


export default {
  name: "Verrechnungspositionen",
  components: {
    ContainerHeadline,
    VerrechnungspositionModal,
    BSpinner,
  },
  mixins: [reiter, xmlExport],
  props: {
    veranstaltung: {
      type: String,
      required: true,
    },
    kuerzel: {
      type: String,
      required: false,
    },
    editable: {
      type: Boolean,
      required: true,
    },
    shown: {
      type: Boolean,
      required: false,
    },
    lastAbacusExport: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      isBusy: false,
      ladeFehler: false,
      sortBy: "",
      verrechnungspositionsCount: 0,
      sortDesc: false,
      verrechnungen: [],
      selected: [],
      selectedIds: [],
      artikel: [],
      verrechnung: {
        id: null,
        veranstaltungId: this.veranstaltung,
        anzahl: 1.0,
        manueller_preis: null,
        pflicht: true,
        artikelId: null,
        artikel: null,
      },
      positionenKey: uuid(),
      exportDate: null
    };
  },
  computed: {
    fields() {
      return [
        {key: "selected", label: ""},
        {
          key: "anzahl",
          sortable: true,
          label: this.$t("global.Number"),
        },
        {
          key: "artikel.artikelnummer",
          sortable: true,
          label: this.$t("global.itemnumber"),
        },
        {
          key: "artikel.bezeichnung",
          sortable: true,
          label: this.$t("global.item"),
        },
        {
          key: "manueller_preis",
          sortable: true,
          label: this.$t("global.price"),
        },
      ];
    },
  },
  created() {},
  watch: {
    shown(val) {
      if (val && this.verrechnungen.length == 0) this.getVerrechnung();
    },
    lastAbacusExport() {
      this.exportDate = this.lastAbacusExport;
    }
  },
  mounted() {
    if (this.shown && this.verrechnungen.length == 0) this.getVerrechnung();
  },
  methods: {
    getVerrechnung() {
      this.isBusy = true;

      Api.get("/rechnungslegung/verrechnungsposition/", {
        params: {veranstaltung: this.veranstaltung},
      })
        .then((response) => {
          this.verrechnungen = response.data;
          this.verrechnungspositionsCount = this.verrechnungen.length;
          this.ladeFehler = false;
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
          this.ladeFehler = true;
        })
        .finally(() => {
          this.isBusy = false;
        });
    },

    showPositionModal(verrechnung) {
      if (verrechnung) this.verrechnung = verrechnung;
      this.$bus.$emit("open-modal", "verrechnungsposition-modal");
    },

    positionChanged() {
      this.getVerrechnung();
    },

    selectAllRows(row) {
      if (row.selected) {
        this.$refs.selectableTable.items.forEach((gp) => {
          gp.selected = true;
          if (!this.selectedIds.includes(gp.id)) {
            this.selectedIds.push(gp.id);
          }
        });
      } else {
        this.$refs.selectableTable.items.forEach((gp) => {
          gp.selected = false;
        });
        this.selectedIds = [];
      }
      this.$forceUpdate();
    },

    onRowSelected(row) {
      if (row.item.selected) {
        this.selectedIds.push(row.item.id);
      } else {
        this.selectedIds = this.selectedIds.filter((id) => id != row.item.id);
      }
    },

    editieren(verrechnung) {
      this.showPositionModal(verrechnung);
    },

    loeschen() {
      this.isBusy = true;

      Api.delete("rechnungslegung/verrechnungsposition/", {
        data: this.selectedIds,
      })
        .then(() => {
          this.verrechnungen = this.verrechnungen.filter(
            (v) => !this.selectedIds.includes(v.id)
          );
          this.verrechnungspositionsCount = this.verrechnungen.length;
          this.selectedIds = [];
          this.$emit("positionChanged");
          this.ladeFehler = false;
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
          this.ladeFehler = true;
        })
        .finally(() => {
          this.isBusy = false;
        });
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const day = `0${date.getDate()}`.slice(-2);
      const month = `0${date.getMonth() + 1}`.slice(-2);
      const year = date.getFullYear();
      const hours = `0${date.getHours()}`.slice(-2);
      const minutes = `0${date.getMinutes()}`.slice(-2);

      return `${day}.${month}.${year} | ${hours}:${minutes}`;
    }
  },
};
</script>

<style></style>
