<template>
  <div>
    <div class="col-12 pl-0">
      <div :class="{ 'mt-30-px': !showNavbar }">
        <notifications></notifications>

        <navbar v-if="showNavbar" :docsName="docsName" />

        <div class="row ml-0 px-5">
          <div v-if="showNavbar" class="col-xl-12">
            <div class="row">
              <div class="col-xl-12 block mb-3 pt-0">
                <head-menu
                  :col="12"
                  class="d-xl-flex"
                  :headline="
                    $t('global.gradeoverviewclass') +
                    ' ' +
                    (this.klassenbezeichnung ? this.klassenbezeichnung : '')
                  "
                ></head-menu>
              </div>
            </div>
          </div>
        </div>

        <div class="row" style="padding: 0 3rem 0 3.95rem !important">
          <div
            class="block block-kopf mr-1 clickable"
            v-for="(gruppe, index) in sektionsgruppen"
            :key="gruppe[index]"
            @click="setSektionsgruppenIndex()"
            :style="
              sektionsgruppenIndex == index
                ? styleHeadlineSelected
                : styleHeadline
            "
          >
            <span class="col primary-headline-text p-0"
              ><router-link
                class="primary-headline-text nav-link p-0"
                :class="{ active: anzeige === 0 }"
                :to="{
                  name: 'Notenuebersicht',
                  params: {
                    klassenid: klassenid,
                    anzeige: index,
                  },
                }"
                >{{ gruppe.bezeichnung }}</router-link
              ></span
            >
          </div>
        </div>
        <div
          class="bock block-kopf"
          style="padding: 0 3rem 0 3.95rem !important"
        >
          <div
            class="row block block-kopf d-flex justify-content-between"
            style="
              padding: 0.75rem 0 0.25rem 0 !important;
              border-radius: 0 !important;
            "
          >
            <div class="ml-4">
              <button
                class="btn btn-success"
                @click="speichern"
                @shortkey="speichern"
                v-if="berechtigungen.b_bildung_bildungsgang.delete"
              >
                <span>{{ $t("global.save") }}</span>
              </button>

              <button
                class="ml-3 btn btn-secondary"
                v-show="!this.bearbeitungGesperrt"
                :disabled="this.notenuebersicht.length == 0"
                @click="bearbeitungSperren"
              >
                <font-awesome-icon icon="fa-solid fa-lock" class="mr-2" />
                <span>{{ $t("global.blockediting") }}</span>
              </button>

              <button
                class="ml-3 btn btn-secondary"
                v-show="
                  this.bearbeitungGesperrt &&
                  !berechtigungen.b_bildung_bildungsgang.delete
                "
                disabled
              >
                <font-awesome-icon icon="fa-solid fa-lock" class="mr-2" />
                <span>{{ $t("global.editingblocked") }}</span>
              </button>

              <button
                class="ml-3 btn btn-secondary"
                v-show="
                  this.bearbeitungGesperrt &&
                  berechtigungen.b_bildung_bildungsgang.delete
                "
                :disabled="this.notenuebersicht.length === 0"
                @click="bearbeitungEntsperren"
              >
                <font-awesome-icon icon="fa-solid fa-unlock" class="mr-2" />
                <span>{{ $t("global.unlockediting") }}</span>
              </button>

              <button
                class="ml-3 btn btn-primary"
                @click="showNotenausweisPdfModal"
              >
                <font-awesome-icon icon="fa-duotone fa-print" class="mr-2" />
                <em>{{ $t("global.printcertificates") }}</em>
              </button>

              <button class="ml-3 btn btn-primary" @click="erstelleExcel">
                <font-awesome-icon icon="fa-duotone fa-print" class="mr-2" />
                <em>{{ $t("global.createexcel") }}</em>
              </button>

              <button
                class="ml-3 btn btn-primary"
                @click="erstelleDiplom($CONST('CARBONEPRINTOUTS'))"
              >
                <font-awesome-icon icon="fa-duotone fa-print" class="mr-2" />
                <em>Erstelle Diplomzeugnis</em>
              </button>
            </div>

            <div class="mr-2">
              <button class="mr-3 btn btn-primary" @click="abbrechen">
                <span>{{ $t("global.cancel") }}</span>
              </button>
            </div>
          </div>
        </div>

        <div class="row ml-0 px-5">
          <div
            class="col-xl-12 block belegliste text-break"
            style="width: 100% !important; border-radius: 0 0 10px 10px"
          >
            <!--  <div class="notenuebersichtTabelle" v-if="loaded">
              <b-table
                hover
                no-border-collapse
                responsive
                sticky-header="70vh"
                ref="selectableTable"
                small
                tbody-tr-class="item"
                :items="filteredList"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :fachnoten="fachnoten"
                sort-icon-left
                selectable
                select-mode="range"
                selected-variant=""
                style="text-align: center !important"
              >
                <template #cell(nr)="data">
                  {{ data.index + 1 }}
                </template>
                <template slot="top-row" slot-scope="{ fields }">
                  <td v-for="field in fields" :key="field.key">
                    <b-input
                      v-if="
                        field.key != 'selected' &&
                        field.searchable == true &&
                        field.list != true
                      "
                      size="sm"
                      v-model="filters[field.key]"
                      :placeholder="field.label"
                    />

                    <v-select
                      v-if="field.list == true"
                      v-model="filters[field.key]"
                      label="bezeichnung"
                      :options="promotionstati"
                      :placeholder="$t('global.promotionstatus')"
                      :reduce="(ps) => ps.id"
                    >
                      <span slot="no-options">{{
                        $t("global.promotionstatus")
                      }}</span>
                    </v-select>
                  </td>
                </template>

                <template v-slot:cell(konferenzentscheid)="row">
                  <div class="form-group m-0 p-0">
                    <v-select
                      v-model="row.item.konferenzentscheid"
                      class="konferenzentscheidDropdown my-3"
                      label="bezeichnung"
                      :options="promotionstati"
                      :placeholder="$t('global.promotionstatus')"
                      @input="promotionsstatusChanged(row)"
                      :disabled="bearbeitungGesperrt"
                    >
                      <span slot="no-options">{{
                        $t("global.taptosearch")
                      }}</span>
                    </v-select>
                  </div>
                </template>

                <template
                  #cell(show_details)="row"
                  class="d-flex align-items-center"
                >
                  <span class="d-flex justify-content-center">
                    <button
                      type="button"
                      size="sm"
                      @click="row.toggleDetails"
                      class="btn btn-primary btn-sm"
                    >
                      {{
                        row.detailsShowing
                          ? $t("global.hide")
                          : $t("global.show")
                      }}
                    </button>
                  </span>
                  <span class="d-flex justify-content-center p-1">
                    <font-awesome-icon
                      v-if="row.item.bemerkung"
                      class="m-1"
                      icon="fa-regular fa-file-alt"
                    />
                  </span>
                </template>

                <template #row-details="row">
                  <b-card>
                    <span class="row mb-2">
                      <span sm="3" class="col-2 text-sm-right"
                        ><b>{{ $t("global.comment") }}: </b></span
                      >
                      <textarea
                        class="col-8 input-lg"
                        type="text"
                        :placeholder="$t('global.comment')"
                        v-model="row.item.bemerkung"
                        :disabled="bearbeitungGesperrt"
                      />
                    </span>
                  </b-card>
                </template>

                <template slot="cell()" slot-scope="{ value }">
                  <div
                    class="d-flex"
                    v-if="value == '0' || value == '' || value == null"
                  >
                    <font-awesome-icon
                      v-tooltip.hover
                      title="Der Teilnehmende hat in diesem Fach noch keine Note hinterlegt oder ist nicht auf dem Fach angemeldet."
                      icon="fa-solid fa-circle-info"
                      class="mx-auto text-danger"
                      style="font-size: 24px"
                    />
                  </div>
                  <div v-else>
                    {{ value }}
                  </div>
                </template>

                <template
                  v-slot:custom-foot
                  v-if="!notendurchschnitt.length == 0"
                >
                  <b-tr
                    class="text-center"
                    style="background-color: #f5fbff; font-weight: bold"
                  >
                    <b-td
                      style="
                        border: none !important;
                        border-top: 2px solid #124862;
                      "
                    ></b-td>
                    <b-td
                      style="
                        border: none !important;
                        border-top: 2px solid #124862;
                      "
                    ></b-td>
                    <b-td
                      style="
                        border: none !important;
                        border-top: 2px solid #124862;
                      "
                    ></b-td>
                    <b-td
                      style="
                        border: none !important;
                        border-top: 2px solid #124862;
                      "
                    ></b-td>
                    <b-td
                      style="
                        border: none !important;
                        border-top: 2px solid #124862;
                      "
                    >
                      {{ $t("global.average") }}:
                    </b-td>
                    <b-td
                      v-for="(durchschnitt, index) in notendurchschnitt"
                      :key="index"
                      style="
                        border: none !important;
                        border-top: 2px solid #124862;
                      "
                      >{{
                        notendurchschnitt[index]
                          ? notendurchschnitt[index].note
                          : ""
                      }}</b-td
                    >
                    <b-td
                      style="
                        border: none !important;
                        border-top: 2px solid #124862;
                      "
                    ></b-td>
                    <b-td
                      style="
                        border: none !important;
                        border-top: 2px solid #124862;
                      "
                    ></b-td>
                    <b-td
                      style="
                        border: none !important;
                        border-top: 2px solid #124862;
                      "
                    ></b-td>
                  </b-tr>
                </template>
              </b-table>
            </div>
            <infinite-loading
              v-if="!loaded"
              :identifier="infiniteId"
              @infinite="infiniteHandler"
            >
              <div slot="no-results"></div>
            </infinite-loading> -->
            <div class="text-center" v-if="noEntries">
              {{ $t("global.nodataavailable") }}
            </div>
          </div>
        </div>
        <pdf-layer
          id="pdf-layer"
          ref="pdfLayer"
          :src="{
            url: pdfPath,
            withCredentials: true,
          }"
          :files="pdfFiles"
        ></pdf-layer>

        <change-watcher-alert />
      </div>
    </div>
    <notenausweis-pdf-modal
      id="notenausweis-pdf-modal"
      :notenausweisAusgeben="notenausweisAusgeben"
      :leistungsausweisAusgeben="leistungsausweisAusgeben"
      :diplomAusgeben="diplomAusgeben"
      :semesternummer="semesternummer"
      :date="date"
      :klassenid="klassenid"
      @dateChanged="updateDate"
    >
    </notenausweis-pdf-modal>
  </div>
</template>

<script>
import HeadMenu from "@/components/HeadMenu";
import Navbar from "@/components/Navbar";

import Api from "@/Api";
import App from "@/AppApi";
import { apiErrorToAlert } from "@/utils/Errorhandler";

import page from "@/mixins/Page";
import ChangeWatcher from "@/mixins/ChangeWatcher";
import dashboard from "@/mixins/Dashboard";
import filter from "@/mixins/Filter";

import server from "@/server";

import NotenausweisPdfModal from "@/components/Modals/NotenausweisPDFModal";
import FileDownload from "js-file-download";

import { LADE_AUSWAHLWERTE_NOTEN } from "@/store/noten/actions.type";
import CarboneApi from "@/CarboneApi";

export default {
  name: "Notenuebersicht",
  components: {
    HeadMenu,
    Navbar,
    NotenausweisPdfModal,
  },

  metaInfo() {
    return {
      titleTemplate: () => {
        return "BX: " + this.$t("global.gradeoverview");
      },
    };
  },
  mixins: [page, ChangeWatcher, dashboard, filter],
  props: {
    klassenid: {
      type: String,
      default: null,
    },
    anzeige: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      page: 0,
      notenuebersicht: [],
      klassenbezeichnung: "",
      fachnoten: [],
      notendurchschnitt: [],
      filteredList: [],
      loaded: null,
      anzahlProPage: 50,
      sortBy: "person.name",
      sortDesc: false,
      editable: true,
      noEntries: null,
      lehrgang: {},
      klasse: {},
      excelFile: {},
      date: new Intl.DateTimeFormat("ch-DE").format(new Date()),
      pdfPath: "",
      semesternummer: "",
      bearbeitungGesperrt: false,
      promotionsstatusListe: [
        "promoviert",
        "nicht promoviert",
        "bedingt promoviert",
      ],
      sektionsgruppen: [],
      sektionsgruppenIndex: 0,
      styleHeadlineSelected: {
        filter: "none",
      },
      styleHeadline: {
        filter: "brightness(0.9)",
      },
      fields: [],
      pdfFiles: [],
    };
  },
  computed: {
    defaultFields() {
      return [
        "nr",
        {
          key: "person.name",
          searchable: true,
          label: this.$t("global.surname"),
          //label: "Nachname",
          sortable: true,
        },
        {
          key: "person.vorname",
          searchable: true,
          label: this.$t("global.firstname"),
          // label: "Vorname",
          sortable: true,
        },
        {
          key: "anmeldetyp.bezeichnung",
          label: this.$t("global.type"),
          //  label: "Typ",
          sortable: true,
          thStyle: "min-width: 60px;",
        },
        {
          key: "abschluss.bezeichnung",
          label: this.$t("global.degree"),
          //label: "Abschluss",
          sortable: true,
        },
        {
          key: "note",
          sortable: true,
          label: "∅",
          tdClass: (value, key, item) => {
            return this.setNotenColorDurchschnitt(item);
          },
        },
        {
          key: "promotionstatus.bezeichnung",
          list: true,
          searchable: true,
          sortable: true,
          //label: this.$t("global.promotionstatus"),
          label: this.$t("global.promotionstatus"),
          tdClass: (value, key, item) => {
            return "table-" + this.setPromotionsstatusColor(item);
          },
        },
        {
          key: "konferenzentscheid",
          //label: this.$t("global.conferencedecision"),
          label: this.$t("global.conferencedecision"),
          sortable: true,
        },
        {
          key: "show_details",
          //label: this.$t("global.comment")
          label: this.$t("global.comment"),
        },
      ];
    },
    promotionstati: {
      get() {
        return this.$store.state.noten.promotionstati;
      },
    },

    queryParams: function () {
      var params = {
        klasseid: this.$route.params.klassenid,
      };

      params.filterNachname = this.setQueryParam(this.filters["person.name"]);
      params.filterVorname = this.setQueryParam(this.filters["person.vorname"]);
      params.promotionsstatus = this.setQueryParam(
        this.filters["promotionstatus.bezeichnung"]
      );

      return params;
    },
  },
  watch: {
    listData: {
      deep: true,
      handler: function (val) {
        this.initializeNotenuebersicht(val);
        // this.initializeFachnoten();
        // this.initializeFields();
        // this.durchschnittBerechnen();
        this.initializeKlassenbezeichnung();
        this.loaded = true;
        this.filteredList = this.listData;
      },
    },
    filters: {
      handler: function () {
        this.filterFunction(this.queryParams);
      },
      deep: true,
    },
    /*  sektionsgruppenIndex: {
      handler: function (val) {
        this.getNotenpromotion(val);
      },
    }, */
  },
  created() {
    this.$store.dispatch(`noten/${LADE_AUSWAHLWERTE_NOTEN}`);
    this.sektionsgruppenIndex = this.$route.params.anzeige;
    this.getSektionsgruppen();
  },
  mounted() {},
  methods: {
    /*
    download() {
      FileDownload(this.excelFile, "notenexport.xlsx");
    },*/
    filterFunction(val) {
      this.filteredList = this.listData.filter(
        (el) =>
          (!val.filterNachname ||
            el.person.personName
              .toLowerCase()
              .includes(val.filterNachname.toLowerCase())) &&
          (!val.filterVorname ||
            el.person.vorname
              .toLowerCase()
              .includes(val.filterVorname.toLowerCase())) &&
          (!val.promotionsstatus ||
            el.promotionstatus.id == val.promotionsstatus)
      );
    },
    setSektionsgruppenIndex() {
      if (this.sektionsgruppenIndex != this.$route.params.anzeige) {
        this.loaded = null;
        this.sektionsgruppenIndex = this.$route.params.anzeige;
      }
    },
    /*** Alte getNotenpromotion, die nach Sektionsgruppe die Notenpromotionen holt ***/
    /*    getNotenpromotion(index) {
      if (this.sektionsgruppen[index]) {
        Api.get("/noten/promotion/", {
          params: {
            klasseid: this.klassenid,
            sgid: this.sektionsgruppen[index].id,
          },
        })
          .then((response) => {
            this.listData = response.data;
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
      }
    }, */

    /*** neue getNotenpromotion, die eine Notenpromotion pro Klasse/Semester holt ***/
    getNotenpromotion() {
      if (this.klassenid) {
        App.get("veranstaltung/notenberechnung/", {
          params: {
            id: this.klassenid,
          },
        })
          .then((response) => {
            this.listData = response.data;
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    getSektionsgruppen() {
      Api.get("veranstaltungen/sektionsgruppe/", {
        params: { id: this.klassenid },
      })
        .then((response) => {
          this.sektionsgruppen = response.data;
          if (response.data.length > 0) {
            this.getNotenpromotion(this.sektionsgruppenIndex);
          } else {
            this.loaded = true;
            this.noEntries = true;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setPromotionsstatusColor(item) {
      if (item.promotionstatus) {
        if (item.promotionstatus.bezeichnung == "promoviert") {
          return "success";
        }
        if (item.promotionstatus.bezeichnung == "bedingt promoviert") {
          return "warning";
        }
        if (item.promotionstatus.bezeichnung == "nicht promoviert") {
          return "danger";
        } else {
          return "primary";
        }
      }
    },

    promotionsstatusChanged() {},
    abbrechen() {
      //condition löschen sobald klasse geladen werden kann
      if (this.klassenid) {
        this.$router.push({
          name: "klasse",
          params: { klassenid: this.klassenid, anzeige: 0 },
        });
      }
    },
    initializeFachnoten() {
      /*if (this.fachnoten.length == 0) {
        this.notenuebersicht[0].fachnoten.forEach((fachnote, index) =>
          this.fachnoten.push({
            key: "fachnoten[" + index + "].note",
            sortable: true,
            bezeichnung: fachnote.bezeichnung.toString(),
            label: fachnote.kuerzel.toString(),
            tdClass: (value, key, item) => {
              if (value < 4 && value > 0) return "table-warning";
            },
          })
        );
      }*/

      this.fachnoten = [];
      this.notenuebersicht[0]?.fachnoten.forEach((fachnote, index) =>
        this.fachnoten.push({
          key: "fachnoten[" + index + "].note",
          sortable: true,
          bezeichnung: fachnote.bezeichnung.toString(),
          label: fachnote.kuerzel.toString(),
          thStyle: "max-width: 100px;",
          formatter: (value, key, item) => {
            if (item.fachnoten[index]?.dispensiert == true) {
              return "Dispensiert";
            } else {
              return value;
            }
          },
          tdClass: (value) => {
            if (value < 4 && value > 0) return "table-warning";
          },
        })
      );
      //Faecher alphabetisch sortieren:
      this.fachnoten.sort(function (a, b) {
        var keyA = a.bezeichnung,
          keyB = b.bezeichnung;
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0;
      });
    },
    initializeKlassenbezeichnung() {
      if (!this.klassenbezeichnung)
        this.klassenbezeichnung =
          this.notenuebersicht?.veranstaltung?.bezeichnung;
    },
    initializeFields() {
      this.fields = this.defaultFields.slice();
      /*if (this.fachnoten?.length > 0) {*/
      for (let i = 0; i < this.fachnoten?.length; i++) {
        this.fields.splice(i + 6, 0, this.fachnoten[i]);
      }
    },
    initializeNotenuebersicht(notenuebersicht) {
      if (notenuebersicht) {
        this.notenuebersicht = notenuebersicht;
        this.semesternummer = this.notenuebersicht[0]
          ? this.notenuebersicht[0]?.faecher[0]
            ? this.notenuebersicht[0]?.faecher[0]?.periodennummer
            : ""
          : "";
        let pos = this.notenuebersicht.findIndex(
          (element) => element.abgeschlossen == true
        );

        if (pos != -1) {
          this.bearbeitungGesperrt = true;
        }
      }

      if (this.notenuebersicht?.length == 0) {
        this.$notify({
          type: "error",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.nogradecalculationaddgradetoclassandcalculate"),
        });
      }
    },
    setNotenColor(item) {
      if (item?.fachnote) {
        if (parseFloat(item.fachnoten.note) < 4) return "table-warning";
      }
    },
    setNotenColorDurchschnitt(item) {
      if (item?.note) {
        if (parseFloat(item.note) < 4) return "table-warning";
      }
    },

    speichern() {
      if (this.loading) return;

      if (this.notenuebersicht?.length > 0) {
        this.loading = true;

        var json = Object.assign({}, this.notenuebersicht);

        Api.put("noten/promotion/", json, {
          params: {
            klassenid: this.klassenid,
          },
        })
          .then((response) => {
            this.initializeNotenuebersicht(response.data);
            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text: this.$t("global.gradeoverviewsavedsuccessfully"),
            });
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.$notify({
          type: "error",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.nogradecalculationaddgradetoclassandcalculate"),
        });
      }
    },

    durchschnittBerechnen() {
      this.notendurchschnitt = [];
      let note = 0;
      let count = 0;
      /*
      let noten = [];

      this.notenuebersicht.forEach((uebersicht, index) => {
        noten[index] = uebersicht;
      });

      noten.forEach((note) => {
        note.fachnoten.sort(function (a, b) {
          a.kuerzel - b.kuerzel;
        });
      });
      Notendurchschnitt als Map und anschliessend sortieren
      */
      for (let k = 0; k < this.notenuebersicht.length; k++) {
        if (this.notenuebersicht[k].note) {
          if (isNaN(this.notenuebersicht[k].note) == false) {
            note += parseFloat(this.notenuebersicht[k].note);
            count++;
          }
        }
      }
      this.notendurchschnitt.push({
        note: (note / count + Number.EPSILON).toFixed(1),
        label: "000",
      });
      note = 0;
      count = 0;
      for (let i = 0; i < this.fachnoten.length; i++) {
        for (let j = 0; j < this.notenuebersicht.length; j++) {
          if (this.notenuebersicht[j]?.fachnoten[i]?.note) {
            if (isNaN(this.notenuebersicht[j]?.fachnoten[i]?.note) == false) {
              note += parseFloat(this.notenuebersicht[j]?.fachnoten[i]?.note);
              count++;
            } else if (
              isNaN(this.notenuebersicht[j]?.fachnoten[i]?.manuelleNote) ==
              false
            ) {
              note += parseFloat(this.notenuebersicht[j]?.fachnoten[i]?.note);
              count++;
            }
          }
          if (j == this.notenuebersicht.length - 1) {
            let label = this.notenuebersicht[j]?.fachnoten[i]?.kuerzel;
            if (count > 0) {
              this.notendurchschnitt.push({
                note: (note / count + Number.EPSILON).toFixed(1),
                label: label,
              });
            } else {
              this.notendurchschnitt.push({
                note: "",
                label: label,
              });
            }
            note = 0;
            count = 0;
          }
        }
      }
      /*
      this.notendurchschnitt.sort((a, b) => {
        return a.label > b.label;
      });
      */

      //SWE: Wenn leere Spalte, wurde der Durchschnitt nicht an der richtigen Stelle angezeigt, deshalb habe ich hier nochmal eine Überprüfung eingebaut von den Indexen
      let sortierteDurschnitte = [
        this.notendurchschnitt.find(
          (durchschnitt) => (durchschnitt.label = "000")
        ),
      ];
      this.fachnoten.forEach((fachnote) => {
        let durchschnitt = this.notendurchschnitt.find((ds) => {
          return ds.label == fachnote.label;
        });
        sortierteDurschnitte.push(durchschnitt);
      });
      this.notendurchschnitt = sortierteDurschnitte;
    },
    bearbeitungSperren() {
      this.notenuebersicht.forEach(
        (uebersicht) => (uebersicht.abgeschlossen = true)
      );
      this.bearbeitungGesperrt = true;
    },

    bearbeitungEntsperren() {
      this.notenuebersicht.forEach(
        (uebersicht) => (uebersicht.abgeschlossen = false)
      );
      this.bearbeitungGesperrt = false;
    },

    notenausweisAusgeben() {
      if (this.notenuebersicht?.length > 0) {
        this.notenuebersicht.forEach((noten) =>
          this.selectedIds.push(noten.id)
        );
        let filename = new Intl.DateTimeFormat("ch").format(new Date());
        if (this.semesternummer == 3) {
          this.pdfPath =
            server.url +
            `/pdfgenerator/pdf.act?filename=Notenausweis${filename}.pdf&report=/pdfgenerator/notenausweisENG.htm&ids=${
              this.klassenid
            }&date=${this.date}&sgid=${
              this.sektionsgruppen[this.sektionsgruppenIndex].id
            }`;
        } else {
          this.pdfPath =
            server.url +
            `/pdfgenerator/pdf.act?filename=Notenausweis${filename}.pdf&report=/pdfgenerator/notenausweis.htm&ids=${
              this.klassenid
            }&date=${this.date}&sgid=${
              this.sektionsgruppen[this.sektionsgruppenIndex].id
            }`;
        }
        this.$refs.pdfLayer.show();
      } else {
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.nodataavailable"),
        });
      }
    },

    leistungsausweisAusgeben() {
      if (this.notenuebersicht?.length > 0) {
        this.notenuebersicht.forEach((noten) =>
          this.selectedIds.push(noten.id)
        );
        let filename = new Intl.DateTimeFormat("ch").format(new Date());
        this.pdfPath =
          server.url +
          `/pdfgenerator/pdf.act?filename=Leistungsausweis${filename}.pdf&report=/pdfgenerator/leistungsausweis.htm&ids=${this.klassenid}&date=${this.date}`;
        this.$refs.pdfLayer.show();
      } else {
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.nogradesavailable"),
        });
      }
    },

    diplomAusgeben() {
      if (this.notenuebersicht?.length > 0) {
        this.notenuebersicht.forEach((noten) =>
          this.selectedIds.push(noten.id)
        );
        //let filename = new Intl.DateTimeFormat("ch").format(new Date());
        let filename = this.klassenbezeichnung.replace(/\s/g, "");
        this.pdfPath =
          server.url +
          `/pdfgenerator/pdf.act?filename=Diplome${filename}.pdf&report=/pdfgenerator/diplom.htm&ids=${
            this.klassenid
          }&date=${this.date}&sgid=${
            this.sektionsgruppen[this.sektionsgruppenIndex].id
          }`;
        this.$refs.pdfLayer.show();
      } else {
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.nogradesavailable"),
        });
      }
    },

    showNotenausweisPdfModal() {
      this.$bus.$emit("open-modal", "notenausweis-pdf-modal");
    },
    updateDate(selectedDate) {
      this.date = selectedDate;
    },
    // erstelleExcel() {
    //   Api.post(
    //     "/noten/notenexport/",
    //     {
    //       semester: {
    //         id: this.klassenid,
    //         bezeichnung: this.klassenbezeichnung,
    //       },
    //       faecher: this.notenuebersicht[0]?.faecher,
    //       rows: this.notenuebersicht,
    //     },
    //     { responseType: "blob" } //SWE: notwendig damit OutputExcel nicht beschädigt wird
    //   )
    //     .then((response) => {
    //       this.excelFile = new Blob([response.data], {
    //         type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    //       });
    //       FileDownload(
    //         this.excelFile,
    //         `${this.klassenbezeichnung}-${new Date().toLocaleDateString()}.xlsx`
    //       );
    //       this.loading = false;
    //     })
    //     .catch(() => {
    //       setTimeout(() => {
    //         this.$notify({
    //           type: "error",
    //           title: this.$t("global.actionfailed"),
    //           text: this.$t("global.errorwhilecreatingtheexcelfile"),
    //         });
    //       }, 2000);
    //       this.loading = false;
    //     });
    // },
    erstelleExcel() {
      Api.post(
        "/noten/notenexport/",
        {
          notenuebersichtdata: this.notenuebersicht,
        },
        { responseType: "blob" } //SWE: notwendig damit OutputExcel nicht beschädigt wird
      )
        .then((response) => {
          this.excelFile = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          FileDownload(
            this.excelFile,
            `${
              this.notenuebersicht?.veranstaltung?.bezeichnung
            }_${new Date().toLocaleDateString()}.xlsx`
          );
          this.loading = false;
        })
        .catch(() => {
          setTimeout(() => {
            this.$notify({
              type: "error",
              title: this.$t("global.actionfailed"),
              text: this.$t("global.errorwhilecreatingtheexcelfile"),
            });
          }, 2000);
          this.loading = false;
        });
    },
    erstelleDiplom(carboneprintouts) {
      let carboneprintout =
        carboneprintouts["HFT_ZEUGNIS_1_STUDIENJAHR_CARBONE"];

      this.carbone(
        carboneprintout.category.bezeichnung,
        carboneprintout.template,
        "carbone/?ids="
      );
    },
    carbone(category, templateID, endpoint) {
      CarboneApi.get(
        endpoint +
          this.filteredList.map((it) => it.id) +
          "&category=" +
          category +
          "&templateId=" +
          templateID
      )
        .then((response) => {
          console.log(response.data);

          this.pdfFiles = response.data.map((item) => ({
            url: server.url + item.url,
            name: item.name,
            count: item.count,
            size: item.name,
          }));

          this.pdfFiles.length > 0
            ? this.$refs.pdfLayer.show()
            : this.$notify({
                type: "warn",
                title: this.$t("global.actionfailed"),
                text: "Es ist ein Fehler aufgetreten",
              });
        })
        .catch((error) => {
          console.error("Error fetching PDF files:", error);
        })
        .finally(() => {
          this.$refs.pdfLayer.loading = false;
        });
    },
  },
};
</script>

<style>
.notenuebersichtTabelle .table > tbody > tr.b-table-row-selected {
  filter: brightness(95%);
  /*position: relative;*/
  z-index: 2;
}
.notenuebersichtTabelle .table > thead > tr > th[aria-sort="ascending"],
.notenuebersichtTabelle .table > thead > tr > th[aria-sort="descending"] {
  background-color: #a7c1cc;
}
.notenuebersichtTabelle .table > thead > tr > th {
  cursor: pointer;
}
.notenuebersichtTabelle .table > thead > tr > th:first-child {
  min-width: 31px;
}
.notenuebersichtTabelle .table > thead > tr > th:nth-child(6) {
  min-width: 45px;
}
.notenuebersichtTabelle
  .b-table-sticky-header
  > .table.b-table
  > thead
  > tr
  > th,
.notenuebersichtTabelle .b-table-sticky-header > .table.b-table > thead > tr,
.notenuebersichtTabelle .b-table-sticky-header > .table.b-table > thead {
  position: -webkit-sticky;
  position: sticky !important;
  top: 0;
  z-index: 10;
  background: #b5cfdc;
}
.notenuebersichtTabelle .b-table-sticky-header {
  scrollbar-width: thin;
  scrollbar-color: #b5cfdc #f5fbff;
}
.notenuebersichtTabelle .konferenzentscheidDropdown ul {
  position: relative;
  z-index: 10;
}
</style>
