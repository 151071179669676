import axios from "axios";
import router from "./Router";
import { notify } from "./utils/Notify"; // Importiere die Notify-Funktion

let carboneApiConfig = {
    baseURL: process.env.VUE_APP_CARBONEAPI_URL,
    defaultURL: process.env.VUE_APP_SERVER_URL,
    withCredentials: true
};

const CarboneApi = axios.create(carboneApiConfig);

CarboneApi.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if (error.response) {
            switch (error.response.status) {
                case 400:
                    router.push({ path: "/error/400" });
                    break;
                case 401:
                    if (
                        router.currentRoute.name !== "login" &&
                        router.currentRoute.name !== "leitsystem"
                    )
                        router.push({ name: "login" });
                    break;
                case 402:
                    router.push({ path: "/error/402" });
                    break;
                case 403:
                    router.push({ path: "/error/403" });
                    break;
                case 404:
                    router.push({ path: "/error/404" });
                    break;
                case 405:
                    router.push({ path: "/error/405" });
                    break;
                case 420:
                    //alert(error.response.data.error || "Ein unbekannter Fehler ist aufgetreten")
                    notify({
                      type: "error",
                      title: "Error",
                      text: error.response.data.error || "Ein unbekannter Fehler ist aufgetreten"
                    });
                    break;
            }
        }
    }
);

export default CarboneApi;
