import axios from "axios";
import router from "./Router";

let apiConfig = {
	baseURL: process.env.VUE_APP_PUBLIC_URL,
	defaultURL: process.env.VUE_APP_SERVER_URL,
	withCredentials: true
};

const PublicApi = axios.create(apiConfig);

PublicApi.interceptors.response.use(
	function (response) {
		return response;
	},
	function (error) {
		if (error.response) {
			switch (error.response.status) {
				case 400:
					router.push({ path: "/error/400" });
					break;
				/*
			case 401:
				router.push({ path: "/error/401" });
				break;*/
				case 402:
					router.push({ path: "/error/402" });
					break;
				case 403:
					router.push({ path: "/error/403" });
					break;
				case 404:
					router.push({ path: "/error/404" });
					break;
				case 405:
					router.push({ path: "/error/405" });
					break;
			}
		}
	}
);

export default PublicApi;